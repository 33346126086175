/**
 * Enums
 */

export enum StickerForms {
  ROUND = "round",
  ANGULAR = "angular"
}

/**
 * Option Definitions
 */

export const BOTTLE_SHAPES = [
  { value: "cylinder", label: "Cylinder" },
  { value: "neck", label: "With Neck" }
];

export const BOTTLE_MATERIALS = [
  { value: "pet", label: "PET" },
  { value: "rpet", label: "R-PET" },
  { value: "hdpe", label: "HDPE" },
  { value: "glass", label: "Glass" },
  { value: "mironGlass", label: "MIRON glass" },
  { value: "recycleable", label: "Biodegradable" }
];

export const COLORS = [
  { value: "black", label: "Black" },
  { value: "white", label: "White" },
  { value: "transparent", label: "Transparent" },
  { value: "brown", label: "Brown" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "aluminium", label: "Aluminium" },
  { value: "gold", label: "Gold" },
  { value: "silver", label: "Silver" }
];

export const BOTTLE_NECKS = [
  { value: "38-400", label: "38 - 400" },
  { value: "45-400", label: "45 - 400" },
  { value: "48-400", label: "48 - 400" },
  { value: "53-400", label: "53 - 400" },
  { value: "58-400", label: "58 - 400" },
  { value: "63-400", label: "63 - 400" },
  { value: "70-400", label: "70 - 400" },
  { value: "75-400", label: "75 - 400" },
  { value: "89-400", label: "89 - 400" },
  { value: "100-400", label: "100 - 400" },
  { value: "120-400", label: "120 - 400" },
  { value: "127-400", label: "127 - 400" },
  { value: "135-400", label: "135 - 400" }
];

export const LIQUIDBOTTLE_NECKS = [
  { value: "18-400", label: "18 - 400" },
  { value: "20-400", label: "20 - 400" },
  { value: "24-410", label: "24 - 410" },
  { value: "28-400", label: "28 - 400" }
].concat(BOTTLE_NECKS);

export const SPRAYPUMP_NORMS = [
  { value: "18", label: "DIN 18" },
  { value: "22", label: "DIN 22" }
];

export const SPRAYPUMP_LENGTH = [
  { value: "22", label: "22" },
  { value: "25", label: "25" },
  { value: "32", label: "32" },
  { value: "36", label: "36" },
  { value: "41", label: "41" },
  { value: "54", label: "54" },
  { value: "72", label: "72" }
];

export const SLEEVE_SIZES = LIQUIDBOTTLE_NECKS.map(o => {
  const split = o.value.split("-");
  return {
    value: split[0].trim(),
    label: split[0].trim()
  };
});

export const LID_SHAPES = [
  { value: "classic", label: "Classic" },
  { value: "riffledclassic", label: "Riffled Classic" },
  { value: "hingegard", label: "Hingegard" },
  { value: "childresistant", label: "Child Resistant" }
];

export const LID_MATERIALS = [
  { value: "pet", label: "PET" },
  { value: "rpet", label: "R-PET" },
  { value: "hdpe", label: "HDPE" },
  { value: "aluminium", label: "Aluminium" },
  { value: "recycleable", label: "Biodegradable" },
  { value: "san", label: "SAN" }
];

export const BOTTLELID_NECKS = BOTTLE_NECKS.map(o => {
  const split = o.value.split("-");
  return { value: `${split[0]} - ${split[1]}`, label: o.label };
});

export const LIQUIDBOTTLELID_NECKS = LIQUIDBOTTLE_NECKS.map(o => {
  const split = o.value.split("-");
  return { value: `${split[0]} - ${split[1]}`, label: o.label };
});

export const BAG_SHAPES = [
  { value: "standup", label: "Stand-Up" },
  { value: "flatbottom", label: "Flatbottom" },
  { value: "quadseal", label: "Quad Seal" },
  { value: "spoutedpouch", label: "Spouted Pouch" },
  { value: "shapedpouch", label: "Shaped Pouch" },
  { value: "3sideseal", label: "3 Side Seal" }
];

export const BAG_MATERIALS = [
  { value: "pet", label: "PET" },
  { value: "paper", label: "Paper" },
  { value: "recycleable", label: "Biodegradable" },
  { value: "polypropylen", label: "PP" }
];

export const BAG_ZIPPERS = [
  { value: "normalzipper", label: "Normal Zipper" },
  { value: "pocketzipper", label: "Pocket Zipper" },
  { value: "aplixvelcrozipper", label: "Aplix/Velcro Zipper" },
  { value: "topsliderzipper", label: "Top Slider Zipper" }
];

export const BOX_QUALITIES = [
  { value: "low", label: "Low Quality" },
  { value: "regular", label: "Regular Quality" },
  { value: "high", label: "High Quality" }
];

export const LABEL_QUALITIES = [
  { value: "glossy", label: "Glossy" },
  { value: "special", label: "Special" },
  { value: "mat", label: "Mat" }
];

export const STICKER_FORMS = [
  { value: StickerForms.ROUND, label: "Round" },
  { value: StickerForms.ANGULAR, label: "Angular" }
];
