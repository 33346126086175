import React, { useCallback } from "react";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import { useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";
import i18n from "../../../../translations/i18n";

interface StorageSpaceInformationProps {
  batch: Batch;
  location: BatchLocation;
}

const StorageSpaceInformation: React.FC<StorageSpaceInformationProps> = ({ batch, location }) => {
  const dispatch = useWarehouseDispatch();

  const handleTriggerAction = useCallback(() => {
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.ASSIGN_STORAGE_SPACE,
        batchIds: [batch._id.toString()],
        locationIds: [location._id.toString()]
      }
    });
  }, [batch._id, location._id]);

  return (
    <div className="kt-user-card-v2 pointer" onClick={handleTriggerAction}>
      <div className="kt-user-card-v2__details">
        <span className={"kt-user-card-v2__name text-black kt-font-bold "}>
          <span>{location.location.storageSpace?.storageSpaceNo || i18n.t("warehouse:entrance")}</span>
        </span>
      </div>
    </div>
  );
};

export default StorageSpaceInformation;
