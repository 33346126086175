import QRCode from "qrcode-svg";
import { DeliveryNoteSettings } from "../../../components/warehouse/modals/DeliveryNoteModal";
import baseUtils from "../../baseUtils";
import { UniversalBarcode } from "../../../model/warehouse/barcode.types";

/**
 * Create a warehouse delivery note
 * @param settings the setting of the delivery note
 * @param identifier the identifier
 * @param universalBarcodes list of available universal barcodes
 * @returns {[string, Date]} tuple of html representation and generation time of delivery note pdf
 */
export const createDeliveryNote = (
  settings: DeliveryNoteSettings,
  identifier: string,
  universalBarcodes: Array<UniversalBarcode>
): [string, Date] => {
  const { title, eta, recipientText, positions, additionalInformation } = settings;
  const generatedTime = new Date();

  const qrCode = new QRCode({
    content: `${process.env.REACT_APP_BASE_URL || ""}delivery/${identifier}`,
    color: "#000000",
    background: "#ffffff",
    width: 110,
    height: 110,
    padding: 0
  });

  return [
    `
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
  <style>
    tr:nth-child(even) {
        background-color: white;
    }
  </style>
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Helvetica ">
<table cellpadding="5" cellspacing="0" style="width: 100%; ">
  <tbody>
    <tr>
      <td style="vertical-align: top">
        <img src="https://private-label-factory.de/config/assets/img/PLF_logo_bw.png" width="270" />
      </td>
      <td>
        <span style="font-size:20px; float:right;">
          <b>Private Label Factory Deutschland GmbH</b>
          <br/>Philipp-Reis-Str. 7
          <br/>45659 Recklinghausen
          <br/>Germany
        </span>
      </td>
    </tr>
    <tr><td>&nbsp;</td></tr>
    <tr>
      <td>
        <span style="font-size:20px; float:left; white-space: pre-wrap">${recipientText}</span>
      </td>
      <td style="font-size:15px">
        <div style="text-align: right;">
         <div>Scan to confirm</div>
         <div>${qrCode.svg()}</div>
         <div>Code: ${identifier}</div>
       </div>
      </td>
    </tr>
  </tbody>
</table>
<table style="width: 100%">
  <tbody  style="width: 100%">
    <tr style="width: 100%">
      <td style="font-size:28px; font-weight: bold; width: 100%">
        <br />Lieferschein ${identifier} - <span style="font-size:25px;">${title}</span>
        <div style="font-size:16px;">Erstellt am ${baseUtils.formatDate(generatedTime, true, undefined, "de-De")}</div>
      </td>
    </tr>
  </tbody>
</table>
<table cellpadding="5" cellspacing="0" style="font-size:15px; width:100%; background-color:#fafafa; margin-top:20px">
  <thead>
    <tr style="background-color:#cccccc">
      <th style="text-align: left; width: 2%"><b>#</b></th>
      <th style="text-align: left; width: 11%">Units</b></th>
      <th style="text-align: left; width: 24%">Title</b></th>
      <th style="text-align: left; width: 33%">Description</b></th>
      <th style="text-align: left; width: 30%">Barcode</b></th>
    </tr>
  </thead>
  <tbody>
  ${positions
    .map(p => {
      const { packagingUnits, title, description, batch, universalBarcode } = p;
      return `
    <tr>
      <td style="padding-top: 10px; padding-bottom: 10px; vertical-align: middle">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" stroke-width="0.2" fill="none" fill-rule="evenodd">
              <g fill="#212121" fill-rule="nonzero">
                  <path d="M5.75,3 L18.25,3 C19.7687831,3 21,4.23121694 21,5.75 L21,18.25 C21,19.7687831 19.7687831,21 18.25,21 L5.75,21 C4.23121694,21 3,19.7687831 3,18.25 L3,5.75 C3,4.23121694 4.23121694,3 5.75,3 Z M5.75,4.5 C5.05964406,4.5 4.5,5.05964406 4.5,5.75 L4.5,18.25 C4.5,18.9403559 5.05964406,19.5 5.75,19.5 L18.25,19.5 C18.9403559,19.5 19.5,18.9403559 19.5,18.25 L19.5,5.75 C19.5,5.05964406 18.9403559,4.5 18.25,4.5 L5.75,4.5 Z" id="🎨Color"></path>
              </g>
          </g>
        </svg>
      </td>
      <td style="padding-top: 10px; padding-bottom: 10px; vertical-align: middle"><b>${packagingUnits.trim()}</b></td>
      <td style="padding-top: 10px; padding-bottom: 10px; vertical-align: middle">
        <b>${title.trim()}</b>
        <br/>
        <span style="font-size: 13px">${batch ? `LOT: ${batch.lot.trim()}` : ""}</span>
      </td>
      <td style="padding-top: 10px; padding-bottom: 10px; vertical-align: middle"><b>${description.trim()}</b></td>
      <td style="padding-top: 10px; padding-bottom: 10px; text-align: left; vertical-align: middle">${getUniversalBarcode(
        universalBarcode,
        universalBarcodes
      )}</td>
    </tr>`;
    })
    .join("")}
  </tbody>
</table>
<br/><br/><br/>
<table>
  <tbody>
    <tr>
      <td >
        <span style="font-size:18px; font-weight: bold;">ETA:</span>
      </td>
      <td>
        <div style="font-size:18px; ">${baseUtils.formatDate(eta, false, undefined, "de-DE")}</div>
      </td>
    </tr>
  </tbody>
</table>
<br/>
<table>
  <tbody>
    <tr>
      <td >
        <span style="font-size:18px; font-weight: bold;">Ergänzende Hinweise:</span>
      </td>
    </tr>
    <tr>
      <td>
        <span style="white-space: pre-wrap">${additionalInformation.trim() || "-"}</span>
      </td>
    </tr>
  </tbody>
</table>
<br/>
<br/>
<hr/><div style="text-align: center; font-size: 14px;">End of document</div>
<br/>
</body>
  `,
    generatedTime
  ];
};

/**
 * Get html string for a universal barcode
 * @param id id of a universal barcode
 * @param universalBarcodes list of all universal barcodes
 * @returns {string} html representation of the barcode
 */
const getUniversalBarcode = (id: string | null, universalBarcodes: Array<UniversalBarcode>): string => {
  if (!id) return "-";
  const universalBarcodeDoc = baseUtils.getDocFromCollection(universalBarcodes, id);
  if (!universalBarcodeDoc) return "-";
  const regex = /viewBox="0 0 (\d+) (\d+)"/.exec(universalBarcodeDoc.barcode);
  if (regex) {
    const [, width, height] = regex;
    const div = document.createElement("div");
    div.style.width = (+width * 0.18).toString() + "px";
    div.style.height = (+height * 0.2).toString() + "px";
    div.innerHTML = universalBarcodeDoc.barcode;
    return `<p>${div.outerHTML}</p>`;
  }
  return "-";
};
