import i18n from "../../../translations/i18n";
import { IndividualBarcode } from "../../../model/warehouse/barcode.types";
import { Batch } from "../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../translationUtils";

/**
 * Get a string in html format with given individual barcodes linking to batch
 * @param batch The batch the barcodes are linking too for headline
 * @param individuals The individual barcodes to be printed
 * @returns {string} The html for the pdf as string
 */
export function createAllBarcodesPDF(batch: Batch, individuals: Array<IndividualBarcode>): string {
  individuals.sort((a, b) => a.packageNumber - b.packageNumber);
  return `
<head>
  <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
  <meta http-equiv="content-type" content="text/html; charset=utf-8">
</head>
<body style="font-family: Helvetica; ">
  <div style="position: absolute; top: 35px; left: 25px">
    <div style="font-size: 40px; font-weight: 900">${i18n.t("warehouse:lot")}: ${batch.lot}</div>
    <span style="font-size: 28px; white-space: nowrap; overflow: hidden; -o-text-overflow: ellipsis; text-overflow: ellipsis; -ms-text-overflow: ellipsis;">${resolveTranslation(
      batch.content.details.title
    )}, ${resolveTranslation(batch.content.details.subtitle)} - ${batch.sender.name}</span>
  </div>
  <div style="position: absolute; top: 150px; left: 25px">
    <table style="font-size: 22px;">
      <tbody>
        ${individuals
          .map((ib, index) => {
            let tr: string = "";
            if (!(index % 2)) {
              tr += `<tr style="width: 100%;"><td style="width: 70%;">${getFormattedBarcode(ib)}</td>`;
            } else {
              tr += `<td style="width: 70%;">${getFormattedBarcode(ib)}</td></tr>`;
            }
            return tr;
          })
          .join("")}
      </tbody>
    </table>
  </div>
</body>
`;
}

/**
 * Get a barcode as formatted svg in html string
 * @param barcode The individual barcode to be formatted
 * @returns {string} The html formatted barcode svg
 */
function getFormattedBarcode(barcode: IndividualBarcode): string {
  const regex = /viewBox="0 0 (\d+) (\d+)"/.exec(barcode.barcode);
  if (regex) {
    const [, width, height] = regex;
    const span = document.createElement("span");
    span.style.display = "inline-block";
    span.style.width = (+width * 0.4).toString() + "px";
    span.style.height = (+height * 0.4).toString() + "px";
    span.style.marginTop = "10px";
    span.style.marginBottom = +height + 10 + "px";
    span.innerHTML = barcode.barcode;
    return `<p>${span.outerHTML}</p>`;
  } else return "";
}
