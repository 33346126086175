import { SelectedEntryType, WarehouseListingTabNames } from "../context/warehouseContext";
import { TabDocument } from "../model/warehouse/customTypes.types";
import {
  isSelectedBatchEntries,
  isSelectedCommodityEntries,
  isSelectedDeliveryAnnouncementEntries,
  isSelectedOrdersEntries
} from "./warehouseActionUtils";
import {
  SelectedBatchEntryType,
  SelectedCommodityEntryType,
  SelectedDeliveryAnnouncementEntryType,
  SelectedOrderEntryType
} from "./warehouseUtils";
import {
  isBatches,
  isCommoditiesWithBatches,
  isDeliveryAnnouncements,
  isOrdersDocuments
} from "./warehouseFilterUtils";

export interface SelectTabPayload {
  tab: WarehouseListingTabNames;
  documents: Array<TabDocument>;
  keepSelected?: boolean;
}

/**
 * Get updated selected entries based on the payload of context action
 * @param currentSelectedEntries list of currently selected entries
 * @param payload payload of the warehouse context action
 * @returns {Array<SelectedEntryType>} updated list of selected entries
 */
export const getUpdatedSelectedEntries = (
  currentSelectedEntries: Array<SelectedEntryType>,
  payload: SelectTabPayload
): Array<SelectedEntryType> => {
  const { documents, keepSelected } = payload;
  if (!keepSelected) return [];
  // Filter out entries not existing anymore
  if (isSelectedBatchEntries(currentSelectedEntries) && isBatches(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedBatchEntryType.BATCH) return documents.some(d => d._id.toString() === e.batchId);
      if (e.type === SelectedBatchEntryType.LOCATION)
        return documents.some(
          d =>
            d._id.toString() === e.batchId &&
            (!e.locationId || d.locations.some(l => l._id.toString() === e.locationId!))
        );
    });
  } else if (isSelectedCommodityEntries(currentSelectedEntries) && isCommoditiesWithBatches(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedCommodityEntryType.COMMODITY) {
        return documents.some(d => d._id.toString() === e.commodityId);
      }
      if (e.type === SelectedCommodityEntryType.BATCH_LOCATION) {
        return documents.some(
          d =>
            d._id.toString() === e.commodityId &&
            (!(e.batchId && e.locationId) ||
              d.batches.some(
                b => b._id.toString() === e.batchId! && b.locations.some(l => l._id.toString() === e.locationId!)
              ))
        );
      }
    });
  } else if (isSelectedDeliveryAnnouncementEntries(currentSelectedEntries) && isDeliveryAnnouncements(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedDeliveryAnnouncementEntryType.NOTIFICATION) {
        return documents.some(d => d._id.toString() === e.deliveryAnnouncementId);
      }
    });
  } else if (isSelectedOrdersEntries(currentSelectedEntries) && isOrdersDocuments(documents)) {
    return currentSelectedEntries.filter(e => {
      if (e.type === SelectedOrderEntryType.ORDER) return documents.some(d => d._id.toString() === e.orderId);
      if (e.type === SelectedOrderEntryType.COMMODITY) {
        return documents.some(
          d =>
            d._id.toString() === e.orderId &&
            (!e.recipeCommodityId || d.calculations[0].prices.some(p => p._id.toString() === e.recipeCommodityId!))
        );
      }
    });
  }
  return [];
};
