import React, { useCallback, useMemo, useState } from "react";
import i18n from "../../../../translations/i18n";
import { DestinationWithOutgoing } from "../../../../model/warehouse/customTypes.types";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import {
  DEFAULTWEIGHTUNIT,
  getFormattedPackagingUnitAmount,
  getFormattedPackagingUnitTotalAmount,
  SelectedDestinationEntryType
} from "../../../../utils/warehouseUtils";
import { NumValue } from "../../../../model/common.types";
import baseUtils, { formatNumValue, formatNumValueLocale } from "../../../../utils/baseUtils";
import { Outgoing } from "../../../../model/warehouse/outgoing.types";
import { PackagingUnit } from "../../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import { WarehouseActionNumber } from "../../../../utils/warehouseActionUtils";

interface DefaultDestinationWithOutgoingRowProps {
  destination: DestinationWithOutgoing;
}
const DefaultDestinationWithOutgoingRow: React.FC<DefaultDestinationWithOutgoingRowProps> = ({ destination }) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const { selectedEntries } = warehouseContext;

  const [showDetails, setShowDetails] = useState(false);

  const uniqueCommoditiesText = useMemo(() => {
    const uniqueCommodities = Array.from(
      new Set(destination.outgoing.map(o => o.batch.content.details._id.toString()))
    ).length;
    return `${uniqueCommodities} ${
      uniqueCommodities > 1 ? i18n.t("warehouse:rawMaterials") : i18n.t("warehouse:rawMaterial")
    }`;
  }, [destination.outgoing]);

  const totalAmounts = useMemo(() => {
    const valueUnitMap = destination.outgoing.reduce((map, o) => {
      const mapCopy = { ...map };
      if (o.movedAmount.unit in map) mapCopy[o.movedAmount.unit] += o.movedAmount.value;
      else mapCopy[o.movedAmount.unit] = o.movedAmount.value;
      return mapCopy;
    }, {} as { [unit: string]: number });

    const valueList: Array<NumValue> = Object.entries(valueUnitMap).map(([unit, value]) => ({
      value,
      unit
    }));
    return valueList.reduce(
      (str, val, idx) =>
        str +
        formatNumValueLocale(val) +
        (idx === valueList.length - 1 ? "" : idx === valueList.length - 2 ? " & " : ", "),
      ""
    );
  }, [destination.outgoing]);

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedDestinationEntryType.DESTINATION &&
          entry.destinationId === destination._id.toString() &&
          !entry.batchId
      ),
    [destination._id, selectedEntries]
  );

  const allChildren = useMemo(() => {
    const allChildren: Array<{ batchId: string; packagingUnitId: string }> = [];
    for (let outgoingObj of destination.outgoing) {
      const packagingUnits = outgoingObj.movedPackagingUnits;
      const pUManaged = [];
      const pURemote = [];
      for (let pU of packagingUnits) {
        if (pU.quantity === null) {
          pURemote.push(pU);
        } else {
          pUManaged.push(pU);
        }
      }
      for (let pU of pUManaged) {
        allChildren.push({
          batchId: outgoingObj.batch._id.toString(),
          packagingUnitId: pU._id.toString()
        });
      }
      if (pURemote.length > 0) {
        allChildren.push({
          batchId: outgoingObj.batch._id.toString(),
          packagingUnitId: "remote"
        });
      }
    }
    return allChildren;
  }, [destination.outgoing]);

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  const handleCheckbox = useCallback(() => {
    dispatch({
      type: WarehouseActionType.SELECT_DESTINATION_ENTRY,
      payload: {
        type: SelectedDestinationEntryType.DESTINATION,
        destinationId: destination._id.toString(),
        numberOfChildren: allChildren.length,
        currentChildren: allChildren
      }
    });
  }, [destination, allChildren]);

  const handleCreateDelivery = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch({
      type: WarehouseActionType.TRIGGER_ACTION,
      payload: {
        actionNumber: WarehouseActionNumber.CREATE_DELIVERY,
        destinationId: destination._id.toString()
      }
    });
  }, []);

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding table-hover" onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <input
                type="checkbox"
                className="ml-0 kt-checkbox--solid"
                checked={checked}
                onClick={e => e.stopPropagation()}
                onChange={handleCheckbox}
              />
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black">{uniqueCommoditiesText}</span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{totalAmounts || `-`}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <span className="kt-user-card-v2__name text-black">
                {destination.outgoing[0].destination.address.name}
              </span>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0">
            <button className="btn btn-sm btn-danger py-0 my-0" onClick={handleCreateDelivery}>
              {i18n.t("warehouse:createDelivery")}
            </button>
          </span>
        </td>
      </tr>
      {showDetails && (
        <tr className={"kt-datatable__row d-table-row "}>
          <td colSpan={9} className="px-0">
            {destination.outgoing.length > 0 && (
              <DefaultDestinationOutgoingTable destination={destination} totalNumberOfOutgoing={allChildren.length} />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultDestinationOutgoingTableProps {
  destination: DestinationWithOutgoing;
  totalNumberOfOutgoing: number;
}

const DefaultDestinationOutgoingTable: React.FC<DefaultDestinationOutgoingTableProps> = ({
  destination,
  totalNumberOfOutgoing
}) => {
  const headerDefinition = useMemo(
    () => [
      { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
      { title: i18n.t("warehouse:rawMaterial"), size: 30 },
      { title: i18n.t("order:lotNumber"), size: 10 },
      { title: i18n.t("warehouse:amount"), size: 14 },
      { title: i18n.t("warehouse:packagingUnitAbbreviation"), size: 15 },
      { title: i18n.t("warehouse:destination"), size: 30 }
    ],
    []
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
      <table className="kt-datatable__table d-table p-5 bg-light">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map((def, idx) => (
              <th key={idx} className="kt-datatable__cell d-table-cell" style={{ width: `${def.size}%` }}>
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {destination.outgoing.map(o => (
            <DefaultDestinationDetailRows
              key={o._id.toString()}
              destination={destination}
              outgoing={o}
              totalNumberOfOutgoing={totalNumberOfOutgoing}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DefaultDestinationDetailRowsProps {
  destination: DestinationWithOutgoing;
  outgoing: Outgoing;
  totalNumberOfOutgoing: number;
}

const DefaultDestinationDetailRows: React.FC<DefaultDestinationDetailRowsProps> = ({
  destination,
  outgoing,
  totalNumberOfOutgoing
}) => {
  const outgoingPUs = useMemo(() => {
    const remote = [];
    const managed = [];
    for (let pU of outgoing.movedPackagingUnits) {
      if (pU.quantity === null) {
        remote.push(pU);
      } else {
        managed.push(pU);
      }
    }
    return { remote, managed };
  }, [outgoing.movedPackagingUnits]);

  return (
    <>
      {outgoingPUs.managed.map((pU, idx) => (
        <DefaultDestinationDetailRow
          key={`${idx}_${pU._id.toString()}`}
          destination={destination}
          outgoing={outgoing}
          packagingUnit={pU}
          totalNumberOfOutgoing={totalNumberOfOutgoing}
        />
      ))}
      {outgoingPUs.remote.length > 0 && (
        // all packaging units from a remote warehouse (quantity null) are displayed in one row, since we don't know which PUs are used for them
        <DefaultDestinationDetailRow
          key="remote"
          destination={destination}
          outgoing={outgoing}
          packagingUnit={outgoingPUs.remote[0]}
          totalNumberOfOutgoing={totalNumberOfOutgoing}
        />
      )}
    </>
  );
};

interface DefaultDestinationDetailRowProps {
  destination: DestinationWithOutgoing;
  outgoing: Outgoing;
  packagingUnit: PackagingUnit;
  totalNumberOfOutgoing: number;
}

const DefaultDestinationDetailRow: React.FC<DefaultDestinationDetailRowProps> = ({
  destination,
  outgoing,
  packagingUnit,
  totalNumberOfOutgoing
}) => {
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { selectedEntries } = warehouseContext;

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedDestinationEntryType.OUTGOING &&
          entry.destinationId === destination._id.toString() &&
          entry.batchId === outgoing.batch._id.toString() &&
          entry.packagingUnitId === (packagingUnit.quantity === null ? "remote" : packagingUnit._id.toString())
      ),
    [destination, outgoing, packagingUnit, selectedEntries]
  );

  const formattedPU = useMemo(
    () =>
      packagingUnit.quantity === null
        ? i18n.t("warehouse:storageSpaceRemote")
        : getFormattedPackagingUnitAmount(packagingUnit, true),
    [packagingUnit]
  );

  const totalAmount = useMemo(() => {
    if (packagingUnit.quantity === null) {
      const amountManagedPUs = outgoing.movedPackagingUnits
        .filter(pU => pU.quantity !== null)
        .reduce((acc, curr) => acc + curr.amountPerPu.value, 0);
      return formatNumValue({ value: outgoing.movedAmount.value - amountManagedPUs, unit: DEFAULTWEIGHTUNIT });
    } else {
      return getFormattedPackagingUnitTotalAmount(packagingUnit);
    }
  }, [packagingUnit]);

  const handleCheckbox = useCallback(
    () =>
      dispatch({
        type: WarehouseActionType.SELECT_DESTINATION_ENTRY,
        payload: {
          type: SelectedDestinationEntryType.OUTGOING,
          destinationId: destination._id.toString(),
          batchId: outgoing.batch._id.toString(),
          packagingUnitId: packagingUnit.quantity === null ? "remote" : packagingUnit._id.toString(),
          numberOfChildren: totalNumberOfOutgoing
        }
      }),
    [destination, outgoing, packagingUnit, totalNumberOfOutgoing]
  );

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input type="checkbox" className="ml-0 kt-checkbox--solid" checked={checked} onChange={handleCheckbox} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black kt-font-bold">
              {baseUtils.truncateString(resolveTranslation(outgoing.batch.content.details.title), 30)}&nbsp;
              <small className="kt-user-card-v2__email mt-0">
                {baseUtils.truncateString(resolveTranslation(outgoing.batch.content.details.subtitle), 30)}
              </small>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black font-weight-300">
              <span>{outgoing.batch.lot}</span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">{totalAmount}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <span className="kt-user-card-v2__email mt-0 text-black">{formattedPU}</span>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black">
              {baseUtils.truncateString(outgoing.destination.address.name, 30)}
            </span>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default DefaultDestinationWithOutgoingRow;
