import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../../context/warehouseContext";
import { useDataContext } from "../../../../context/dataContext";
import {
  DEFAULTORDERUNIT,
  getAvailableAmountForMaterial,
  SelectedOrderEntryType
} from "../../../../utils/warehouseUtils";
import baseUtils from "../../../../utils/baseUtils";
import orderUtils, {
  CommodityReservationState,
  OrderReservationState,
  OrderReservationStateOverview
} from "../../../../utils/orderUtils";
import { getTotalAmountForMaterial } from "../../../../utils/batchUtils";
import { getReservedAmountForMaterial } from "../../../../utils/reservationUtils";
import { resolveTranslation } from "../../../../utils/translationUtils";
import calculationUtils from "../../../../utils/calculationUtils";
import { Reservation, ReservationState } from "../../../../model/warehouse/reservation.types";
import { ContentType } from "../../../../model/warehouse/common.types";
import { OrdersDocument, pricingCommodities } from "../../../../model/orders.types";
import { WeightUnit } from "../../../../model/common.types";
import OrderCommodityReservationState from "../common/OrderCommodityReservationState";
import OrderReservationStateInformation from "../common/OrderReservationStateInformation";
import { convertNumValueWeight } from "../../../../utils/warehouseCalculationUtils";

interface DefaultOrderRowProps {
  order: OrdersDocument;
}

const DefaultOrderRow: React.FC<DefaultOrderRowProps> = ({ order }) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { selectedEntries } = warehouseContext;
  const { reservation, companies, batch } = dataContext;

  const [showDetails, setShowDetails] = useState(false);

  const orderReservation = useMemo(
    () => reservation.find(r => r.order._id.toString() === order._id.toString() && r.state === ReservationState.OPEN),
    [reservation, order]
  );

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedOrderEntryType.ORDER &&
          entry.orderId === order._id.toString() &&
          !entry.recipeCommodityId
      ),
    [order, selectedEntries]
  );

  const createdFor = useMemo(
    () => baseUtils.getDocFromCollection(companies, order.createdFor),
    [companies, order.createdFor]
  );

  const orderReservationState: OrderReservationStateOverview = useMemo(() => {
    let ready = 0;
    let possible = 0;
    let missing = 0;
    let unknown = 0;

    for (const pricingCommodities of order.calculations[0].prices) {
      // How much is in the warehouse, physically, available
      const totalAmountInWarehouseNumValue = getTotalAmountForMaterial(batch, pricingCommodities._id.toString());
      const totalAmountInWarehouse = totalAmountInWarehouseNumValue
        ? convertNumValueWeight(totalAmountInWarehouseNumValue, WeightUnit.mg).value
        : 0;

      // How much is theoretically available after taking all reservations into account (can be negative)
      const availableAmountNumVal = getAvailableAmountForMaterial(
        batch,
        reservation,
        pricingCommodities._id.toString()
      );
      const available = availableAmountNumVal ? convertNumValueWeight(availableAmountNumVal, WeightUnit.mg).value : 0;

      // How much is needed for the order to be fulfilled
      const totalNeededForOrder = orderUtils.getTotalAmountWithBuffer(
        +order.settings.perUnit,
        order.calculations[0].units,
        pricingCommodities.amount,
        pricingCommodities.buffer,
        order.settings.type
      );

      // How much is theoretically reserved for this order
      let reservedForOrder = 0;
      if (orderReservation) {
        const matchingMaterials = orderReservation.materials.filter(
          material =>
            material.material.type === ContentType.COMMODITY &&
            material.material.details._id.toString() === pricingCommodities._id.toString()
        );
        reservedForOrder = matchingMaterials.reduce(
          (acc, material) =>
            acc +
            Number(
              calculationUtils.convertAmount(
                material.reservedAmount.value.toString(),
                material.reservedAmount.unit,
                DEFAULTORDERUNIT
              )
            ),
          0
        );
      }
      // How much of the stock can fulfill the reservation made for the order
      const fulfillableOrderReservation = Math.min(reservedForOrder, totalAmountInWarehouse);

      // How much is theoretically reserved for other orders
      const reservedForOtherOrdersNumVal = getReservedAmountForMaterial(
        reservation,
        pricingCommodities._id.toString(),
        [order._id.toString()]
      );
      const reservedForOtherOrders = reservedForOtherOrdersNumVal
        ? convertNumValueWeight(reservedForOtherOrdersNumVal, WeightUnit.mg).value
        : 0;
      // how much of the stock would be used to fulfill other orders, assuming this one is fulfilled first
      const fulfillableOtherOrderReservations = Math.min(
        reservedForOtherOrders,
        totalAmountInWarehouse - fulfillableOrderReservation
      );

      if (available + fulfillableOrderReservation >= totalNeededForOrder) {
        ready++;
      } else if (
        available + fulfillableOrderReservation < totalNeededForOrder &&
        fulfillableOtherOrderReservations >= totalNeededForOrder - available - fulfillableOrderReservation
      ) {
        possible++;
      } else if (totalAmountInWarehouse < totalNeededForOrder) {
        missing++;
      } else {
        unknown++; // this should not happen
      }
    }
    return {
      state:
        unknown > 0
          ? OrderReservationState.UNKNOWN_STATUS
          : missing > 0
          ? OrderReservationState.MISSING_COMMODITIES
          : possible > 0
          ? OrderReservationState.RESERVED_FOR_OTHERS
          : OrderReservationState.READY,
      color: missing > 0 || unknown > 0 ? "text-danger" : possible > 0 ? "text-warning" : "text-success",
      ready,
      possible,
      missing
    };
  }, [order, batch, reservation, orderReservation]);

  const handleToggleDetails = useCallback(() => setShowDetails(prevState => !prevState), []);

  const handleCheckbox = useCallback(() => {
    dispatch({
      type: WarehouseActionType.SELECT_ORDER_ENTRY,
      payload: {
        type: SelectedOrderEntryType.ORDER,
        orderId: order._id.toString(),
        numberOfChildren: order.calculations[0].prices.length,
        currentChildren: order.calculations[0].prices.map(p => p._id.toString())
      }
    });
  }, [order]);

  return (
    <>
      <tr className="kt-datatable__row d-table-row nopadding table-hover" onClick={handleToggleDetails}>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <input
                type="checkbox"
                className="ml-0 kt-checkbox--solid"
                checked={checked}
                onClick={e => e.stopPropagation()}
                onChange={handleCheckbox}
              />
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <div className="kt-user-card-v2">
            <div className="kt-user-card-v2__details">
              <Link
                to={"/order/" + order._id.toString()}
                className="kt-user-card-v2__name text-dark "
                style={{ lineHeight: "1.2rem" }}
              >
                AT-{order.identifier}
              </Link>
            </div>
          </div>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">
            <span className="kt-user-card-v2__email mt-0">{baseUtils.truncateString(order.title, 30)}</span>
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email mt-0 text-black">{createdFor.name}</span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <span className="kt-user-card-v2__email text-black">
            {order.targetDate ? baseUtils.formatDate(order.targetDate) : "-"}
          </span>
        </td>
        <td className="kt-datatable__cell d-table-cell">
          <OrderReservationStateInformation orderState={orderReservationState} order={order} />
        </td>
      </tr>
      {showDetails && (
        <tr className="kt-datatable__row d-table-row">
          <td colSpan={10} className="px-0">
            {order.calculations[0].prices.length > 0 && (
              <DefaultOrderTable order={order} orderReservation={orderReservation} />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

interface DefaultOrderRowCommodityTableProps {
  order: OrdersDocument;
  orderReservation?: Reservation;
}

const DefaultOrderTable: React.FC<DefaultOrderRowCommodityTableProps> = ({ order, orderReservation }) => {
  const headerDefinition = useMemo(
    () => [
      { title: <i className="fas fa-lock text-muted" style={{ right: 0 }} />, size: 1 },
      { title: "#", size: 1 },
      { title: i18n.t("warehouse:commodity"), size: 25 },
      { title: i18n.t("warehouse:needed"), size: 15 },
      { title: i18n.t("warehouse:reserved"), size: 15 },
      { title: i18n.t("warehouse:location"), size: 16 },
      { title: i18n.t("warehouse:status"), size: 27, additionalClass: "text-right" }
    ],
    []
  );

  return (
    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded table-responsive p-2 bg-light mb-0">
      <table className="kt-datatable__table d-table p-5 bg-light">
        <thead className="kt-datatable__head header-no-padding" style={{ display: "table-header-group" }}>
          <tr className="kt-datatable__row d-table-row">
            {headerDefinition.map((def, idx) => (
              <th
                key={idx}
                className={"kt-datatable__cell d-table-cell " + def.additionalClass}
                style={{ width: `${def.size}%` }}
              >
                <span>{def.title}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="kt-datatable__body" style={{ display: "table-row-group" }}>
          {order.calculations[0].prices.map(p => (
            <DefaultOrderDetailRow
              key={p._id.toString()}
              order={order}
              pricingCommodities={p}
              numberOfSiblingLocations={order.calculations[0].prices.length}
              orderReservation={orderReservation}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface DefaultOrderDetailRowProps {
  order: OrdersDocument;
  pricingCommodities: pricingCommodities;
  numberOfSiblingLocations: number;
  orderReservation?: Reservation;
}

const DefaultOrderDetailRow: React.FC<DefaultOrderDetailRowProps> = ({
  order,
  pricingCommodities,
  numberOfSiblingLocations,
  orderReservation
}) => {
  const dispatch = useWarehouseDispatch();
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { selectedEntries } = warehouseContext;
  const { commodities } = dataContext;

  const commodity = useMemo(
    () => commodities.find(c => c._id.toString() === pricingCommodities._id.toString()),
    [commodities, pricingCommodities]
  );

  const checked = useMemo(
    () =>
      selectedEntries.some(
        entry =>
          entry.type === SelectedOrderEntryType.COMMODITY &&
          entry.orderId === order._id.toString() &&
          entry.recipeCommodityId === pricingCommodities._id.toString()
      ),
    [selectedEntries, order, pricingCommodities]
  );

  const neededAmount = useMemo(
    () =>
      orderUtils.getTotalAmountWithBuffer(
        +order.settings.perUnit,
        order.calculations[0].units,
        pricingCommodities.amount,
        pricingCommodities.buffer,
        order.settings.type
      ),
    [order.settings, order.calculations, pricingCommodities]
  );

  const matchingMaterials = useMemo(() => {
    if (!orderReservation) return [];
    return orderReservation.materials.filter(
      material =>
        material.material.type === ContentType.COMMODITY &&
        material.material.details._id.toString() === pricingCommodities._id.toString()
    );
  }, [orderReservation, pricingCommodities]);

  const reservedAmount = useMemo(() => {
    if (matchingMaterials.length === 0) return 0;
    return matchingMaterials.reduce(
      (acc, material) =>
        acc +
        Number(
          calculationUtils.convertAmount(
            material.reservedAmount.value.toString(),
            material.reservedAmount.unit,
            DEFAULTORDERUNIT
          )
        ),
      0
    );
  }, [matchingMaterials]);

  const uniqueLocations = useMemo(
    () => Array.from(new Set(matchingMaterials.map(m => m.warehouseSnapshot))),
    [matchingMaterials]
  );

  const [status, color] = useMemo(() => {
    let statusText = "N/A";
    let color = "text-warning";
    if (reservedAmount === 0) {
      statusText = i18n.t("warehouse:" + CommodityReservationState.RESERVATION_PENDING);
      color = "text-danger";
    } else {
      const difference = neededAmount - reservedAmount;
      if (difference > 0) {
        statusText = i18n.t("warehouse:" + CommodityReservationState.PARTIALLY_RESERVED);
        color = "text-warning";
      } else {
        statusText = i18n.t("warehouse:" + CommodityReservationState.COMPLETELY_RESERVED);
        color = "text-success";
      }
    }
    return [statusText, color];
  }, [reservedAmount, neededAmount]);

  const handleCheckbox = useCallback(() => {
    dispatch({
      type: WarehouseActionType.SELECT_ORDER_ENTRY,
      payload: {
        type: SelectedOrderEntryType.COMMODITY,
        orderId: order._id.toString(),
        numberOfChildren: numberOfSiblingLocations,
        recipeCommodityId: pricingCommodities._id.toString()
      }
    });
  }, [order, numberOfSiblingLocations, pricingCommodities]);

  return (
    <tr className="kt-datatable__row d-table-row nopadding">
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details ">
            <i className={"flaticon-add-label-button icon-md " + color} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input type="checkbox" className="ml-0 kt-checkbox--solid" checked={checked} onChange={handleCheckbox} />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2 ">
          <div className="kt-user-card-v2__details">
            <span className="kt-user-card-v2__name text-black">
              <span className="d-inline-block text-ellipsis align-middle" style={{ maxWidth: "250px" }}>
                {commodity ? resolveTranslation(commodity.title) : "-"}
              </span>
            </span>
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            ca. {calculationUtils.formatAmount(neededAmount, 2)}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {reservedAmount > 0 ? `${calculationUtils.formatAmount(reservedAmount, 2)}` : "0kg"}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details text-black">
            {uniqueLocations.length === 0
              ? "-"
              : uniqueLocations.length === 1
              ? resolveTranslation(uniqueLocations[0].warehouseName)
              : i18n.t("warehouse:various")}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <OrderCommodityReservationState
          color={color}
          statusText={status}
          order={order}
          commodity={pricingCommodities}
        />
      </td>
    </tr>
  );
};

export default DefaultOrderRow;
