import React, { useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import i18n from "../../../translations/i18n";
import { useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { WarehouseActionNumber } from "../../../utils/warehouseActionUtils";

export interface ReservationStatusContextMenuProps {
  reservationId: string;
  materialId: string;
  locationId: string;
}

export const ReservationStatusContextMenu: React.FC<ReservationStatusContextMenuProps> = ({
  reservationId,
  materialId,
  locationId
}) => {
  const dispatch = useWarehouseDispatch();
  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const [show, setShow] = useState(false);
  const [statusColor, statusText] = ["text-warning", i18n.t("warehouse:reserved")];

  const handleShow = useCallback(
    (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      setShow(!show);
    },
    [show]
  );
  const handleHide = useCallback(() => setShow(false), []);

  const handleTriggerAction = useCallback(
    (number: WarehouseActionNumber) => {
      setShow(false);
      dispatch({
        type: WarehouseActionType.TRIGGER_ACTION,
        payload: { actionNumber: number, reservationId, materialId, locationIds: [locationId] }
      });
    },
    [reservationId, materialId, locationId]
  );

  const handleCancelReservation = useCallback(() => handleTriggerAction(WarehouseActionNumber.CANCEL_RESERVATION), []);

  return (
    <>
      <span className={"kt-font-bold " + statusColor} onClick={handleShow} role="button" style={{ paddingLeft: "1px" }}>
        <span ref={overlayRef}>{statusText}</span>
      </span>
      <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement={"bottom-start"}>
        <Popover id={"dropdown-popover"}>
          <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
            <span className="dropdown-item text-black cursorhand" onClick={handleCancelReservation}>
              {i18n.t("warehouse:cancelReservationShort")}
            </span>
          </div>
        </Popover>
      </Overlay>
    </>
  );
};
