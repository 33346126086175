import React, { useMemo } from "react";
import i18n from "../../../../translations/i18n";
import {
  BatchStorageSpaceAssignment,
  LocationStorageSpaceAssignment,
  LocationType,
  PUStorageSpaceAssignment
} from "../../../../model/warehouse/common.types";
import { resolveTranslation } from "../../../../utils/translationUtils";
import Tooltip from "../../../common/Tooltip";
import { getLocationString, getPUString } from "../../../../utils/batchUtils";
import { LanguageObject, SelectOption } from "../../../../model/common.types";

interface StorageSpaceAssignmentOverviewProps {
  batchAssignments: Array<BatchStorageSpaceAssignment> | undefined;
  selectOptions: Array<SelectOption<LocationType>>;
}

const StorageSpaceAssignmentOverview: React.FC<StorageSpaceAssignmentOverviewProps> = ({
  batchAssignments,
  selectOptions
}) => {
  return (
    <div className="px-2">
      <div className="kt-portlet__body">
        <h5 className="font-weight-bold text-black">{i18n.t("common:summary")}</h5>
        {batchAssignments && batchAssignments.length > 0 ? (
          <>
            {batchAssignments.map((assignment, idx) => (
              <BatchAssignmentRow key={idx} batchAssignment={assignment} selectOptions={selectOptions} />
            ))}
          </>
        ) : (
          <div className="row mt-2">
            <div className="col-12 kt-font-dark">{i18n.t("warehouse:storageSpaceAssignmentNoChangeError")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

interface BatchAssignmentRowProps {
  batchAssignment: BatchStorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
}

const BatchAssignmentRow: React.FC<BatchAssignmentRowProps> = ({ batchAssignment, selectOptions }) => {
  return (
    <>
      <hr />
      <div className="mb-2">
        <div className="row">
          <div className="col-12">
            <span className="kt-font-bold kt-font-dark">
              <Tooltip
                tooltipText={`${resolveTranslation(
                  batchAssignment.batchInfo.contentSnapshot.title
                )} ${resolveTranslation(batchAssignment.batchInfo.contentSnapshot.subtitle)}`}
              >
                <span className="kt-font-dark">
                  {resolveTranslation(batchAssignment.batchInfo.contentSnapshot.title)}
                </span>
              </Tooltip>
            </span>
            <span className="kt-font-dark ml-2">
              {i18n.t("warehouse:lot")}: {batchAssignment.batchInfo.lot}
            </span>
          </div>
        </div>
        {batchAssignment.locationAssignments.map((assignment, idx) => (
          <LocationAssignmentRow key={idx} locationAssignment={assignment} selectOptions={selectOptions} />
        ))}
      </div>
    </>
  );
};

type ExtendedPUStorageSpaceAssignment = {
  pULabel: LanguageObject | undefined;
  pUStorageSpaceAssignment: Array<PUStorageSpaceAssignment>;
};

interface LocationAssignmentRowProps {
  locationAssignment: LocationStorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
}

const LocationAssignmentRow: React.FC<LocationAssignmentRowProps> = ({ locationAssignment, selectOptions }) => {
  const fromLocation = useMemo(
    () => getLocationString(locationAssignment.batchLocation.location),
    [locationAssignment.batchLocation.location]
  );

  const extendedPUAssignment = useMemo(() => {
    const extended: { [pUId: string]: ExtendedPUStorageSpaceAssignment } = {};
    for (const [pUId, pUStorageSpaceAssignment] of Object.entries(locationAssignment.pUAssignment)) {
      const pUSnapshot = locationAssignment.batchLocation.packagingUnits.find(pU => pU._id.toString() === pUId);
      extended[pUId] = {
        pULabel: pUSnapshot?.puSnapshot.label,
        pUStorageSpaceAssignment
      };
    }
    return extended;
  }, [locationAssignment]);

  return (
    <div className="kt-font-dark">
      <div>{i18n.t("warehouse:fromLocation", { location: fromLocation })}</div>
      {Object.values(extendedPUAssignment).map((assignment, idx) => (
        <PUAssignmentRow key={idx} locationAssignment={assignment} selectOptions={selectOptions} />
      ))}
    </div>
  );
};

interface PUAssignmentRowProps {
  locationAssignment: ExtendedPUStorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
}

const PUAssignmentRow: React.FC<PUAssignmentRowProps> = ({ locationAssignment, selectOptions }) => {
  return (
    <ul className="list-unstyled mb-1">
      {locationAssignment.pUStorageSpaceAssignment.map((assignment, idx) => (
        <NewLocationRow
          key={idx}
          assignment={assignment}
          selectOptions={selectOptions}
          pULabel={locationAssignment.pULabel}
        />
      ))}
    </ul>
  );
};

interface NewLocationRowProps {
  assignment: PUStorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
  pULabel: LanguageObject | undefined;
}

const NewLocationRow: React.FC<NewLocationRowProps> = ({ assignment, selectOptions, pULabel }) => {
  const locationName = useMemo(() => {
    const selectOption = selectOptions.find(sO => sO.value === assignment.location);
    return selectOption ? selectOption.label : i18n.t("warehouse:unknownStorageSpace");
  }, [selectOptions, assignment.location]);

  const pUName = useMemo(() => getPUString(pULabel, assignment.quantity), [pULabel, assignment.quantity]);

  return (
    <li className="ml-4">
      {pUName} → {locationName}
    </li>
  );
};

export default StorageSpaceAssignmentOverview;
