import i18n from "../translations/i18n";
import {
  PackagingUnitShape,
  PhysicalWarehouse,
  StorageSpace,
  WarehouseDefinition
} from "../model/configuration/warehouseConfiguration.types";
import {
  LocationType,
  PUStorageSpaceAssignmentRec,
  PUStorageSpaceAssignment,
  StorageSpaceOccupation,
  StorageSpaceBatchInfo
} from "../model/warehouse/common.types";
import { getBatchLocationInformationObject } from "./batchUtils";
import { Batch, BatchLocationInformation } from "../model/warehouse/batch.types";
import { RemoteWarehouseAssignment } from "../components/warehouse/common/AmountEntry";
import { BasicSelectOption, SelectOption, WeightUnit } from "../model/common.types";
import { convertNumValueWeight } from "./warehouseCalculationUtils";

/**
 * Retrieves the storage spaces from the given physical warehouse and returns them as select options
 * @param physicalWarehouse the physical warehouse from which all storage spaces should be retrieved from
 * @returns {Array<SelectOption<LocationType>>} storage spaces as select options or empty array if no storage spaces were found
 */
export function getStorageSpaceSelectOptions(
  physicalWarehouse: PhysicalWarehouse | undefined
): Array<SelectOption<LocationType>> {
  if (
    physicalWarehouse === undefined ||
    !physicalWarehouse.storageSpaces ||
    physicalWarehouse.storageSpaces.length === 0
  )
    return [];
  const storageSpaces: Array<SelectOption<LocationType>> = physicalWarehouse.storageSpaces.map(storageSpace => ({
    value: storageSpace._id.toString(),
    label: storageSpace.storageSpaceNo,
    data: LocationType.STORAGESPACE
  }));
  const entrance = {
    value: physicalWarehouse._id.toString(),
    label: i18n.t("warehouse:incomingTab"),
    data: LocationType.PHYSICAL_WAREHOUSE
  };
  return [entrance, ...storageSpaces];
}

/**
 * Returns the shape options as select options
 * @returns {Array<BasicSelectOption>} shape options as select options
 */
export function getStorageSpaceShapeOptions(): Array<BasicSelectOption> {
  return [
    { value: PackagingUnitShape.ROUND, label: i18n.t("warehouse:round") },
    {
      value: PackagingUnitShape.ANGULAR,
      label: i18n.t("warehouse:angular")
    }
  ];
}

/**
 * Retrieves the warehouse containing the given storage space
 * @param warehouse the logical warehouse which should be searched
 * @param storageSpaceId the id of the searched storage space
 * @returns {{ physicalWarehouse: PhysicalWarehouse, storageSpace: StorageSpace } | undefined} the physical warehouse and resolved storage space if the storage space was found in the warehouse, undefined if no physical warehouse with the given storage space was found
 */
export function getWarehouseWithStorageSpace(
  warehouse: WarehouseDefinition,
  storageSpaceId: string
): { physicalWarehouse: PhysicalWarehouse; storageSpace: StorageSpace } | undefined {
  let physicalWarehouse: PhysicalWarehouse | undefined;
  let storageSpace: StorageSpace | undefined = undefined;

  physicalWarehouse = warehouse.physicalWarehouses.find(warehouse => {
    storageSpace = warehouse.storageSpaces?.find(st => st._id.toString() === storageSpaceId);
    return storageSpace !== undefined;
  });
  if (physicalWarehouse === undefined || storageSpace === undefined) {
    return;
  } else {
    return { physicalWarehouse, storageSpace };
  }
}

/**
 * Returns the batch location information for a given storage space
 * @param locationId the id of the storage space or physical warehouse for which the BatchLocationInformation should be retrieved
 * @param locationType the type of the given id
 * @param warehouseStructure optional, if given, all warehouses will be searched
 * @param warehouseDefinition optional, if given only the physical warehouses from that definition will be searched
 * @returns {BatchLocationInformation | undefined} the batch location or undefined if the locationId was not found or the locationType not recognized
 */
export function getBatchLocationInformation(
  locationId: string,
  locationType: LocationType,
  warehouseStructure?: Array<WarehouseDefinition>,
  warehouseDefinition?: WarehouseDefinition
): BatchLocationInformation | undefined {
  let logicalWarehouse: WarehouseDefinition | undefined = undefined;
  let physicalWarehouse: PhysicalWarehouse | undefined = undefined;
  let storageSpace: StorageSpace | undefined = undefined;

  if (locationType === LocationType.STORAGESPACE) {
    if (warehouseDefinition) {
      logicalWarehouse = warehouseDefinition;
      const warehouseResult = getWarehouseWithStorageSpace(warehouseDefinition, locationId);
      if (warehouseResult !== undefined) {
        physicalWarehouse = warehouseResult.physicalWarehouse;
        storageSpace = warehouseResult.storageSpace;
      }
    } else if (warehouseStructure) {
      for (let i = 0; i < warehouseStructure.length; i++) {
        const searchedWarehouse = getWarehouseWithStorageSpace(warehouseStructure[i], locationId);
        if (searchedWarehouse !== undefined) {
          logicalWarehouse = warehouseStructure[i];
          physicalWarehouse = searchedWarehouse.physicalWarehouse;
          storageSpace = searchedWarehouse.storageSpace;
          break;
        }
      }
    }
    if (!logicalWarehouse || !physicalWarehouse || !storageSpace) return;
    return getBatchLocationInformationObject(logicalWarehouse, physicalWarehouse, storageSpace);
  } else if (locationType === LocationType.PHYSICAL_WAREHOUSE) {
    if (warehouseDefinition) {
      logicalWarehouse = warehouseDefinition;
      physicalWarehouse = warehouseDefinition.physicalWarehouses.find(pW => pW._id.toString() === locationId);
    } else if (warehouseStructure) {
      for (let i = 0; i < warehouseStructure.length; i++) {
        const searchedWarehouse = warehouseStructure[i].physicalWarehouses.find(pW => pW._id.toString() === locationId);
        if (searchedWarehouse !== undefined) {
          logicalWarehouse = warehouseStructure[i];
          physicalWarehouse = searchedWarehouse;
          break;
        }
      }
    }
    if (!logicalWarehouse || !physicalWarehouse) return;
    return getBatchLocationInformationObject(logicalWarehouse, physicalWarehouse);
  }
  return undefined;
}

/**
 * Checks if the given storageSpaceAssignments contain duplicate locations
 * @param storageSpaceAssignments the storage space assignments to check for duplicate locations
 * @returns {boolean} true if duplicate locations were found, false if not
 */
export function hasDuplicateLocations(
  storageSpaceAssignments: Array<PUStorageSpaceAssignment | RemoteWarehouseAssignment>
): boolean {
  const locationCounts = storageSpaceAssignments.reduce((counts, assignment) => {
    const location = assignment.location;
    if (location) {
      counts.set(location, (counts.get(location) || 0) + 1);
    }
    return counts;
  }, new Map<string, number>());
  return Array.from(locationCounts.values()).some(count => count > 1);
}

/**
 * Checks if all entries in the puStorageSpaceAssignment have the same destination
 * @param puStorageSpaceAssignment the storage space assignments for all packaging units which should be checked for same locations
 * @returns {boolean} true if one destination is selected for all entries, false if not
 */
export function hasOneLocation(puStorageSpaceAssignment: PUStorageSpaceAssignmentRec): boolean {
  const uniqueLocation = new Set<string | undefined>();
  for (const pu in puStorageSpaceAssignment) {
    const assignments = puStorageSpaceAssignment[pu];
    const locations = assignments.map(assignment => assignment.location);
    for (const location of locations) {
      uniqueLocation.add(location);
    }
  }
  return uniqueLocation.size === 1;
}

/**
 * Retrieves the text and background color based on the occupation of a storage space
 * @param occupation information on how occupied the storage space is
 * @returns {[textColor: string, bgColor: string]} the text and background color based on the occupation
 */
export function getStorageSpaceOccupationColor(
  occupation?: StorageSpaceOccupation
): [textColor: string, bgColor: string] {
  if (!occupation || !occupation.locationSnapshot.maxWeight) return ["triangle-muted", "bg-muted"];

  // Make sure maxWeight and totalAmount are in the same unit for comparison
  const convertedMaxWeight = convertNumValueWeight(
    occupation.locationSnapshot.maxWeight,
    occupation.totalAmount.unit as WeightUnit
  );
  // 3/4 of max storage space capacity as color threshold
  const thresholdOccupation = convertedMaxWeight.value * (3 / 4);
  if (occupation.totalAmount.value > thresholdOccupation) return ["text-danger", "bg-danger"];
  if (occupation.totalAmount.value <= thresholdOccupation && occupation.totalAmount.value > 0)
    return ["text-warning", "bg-warning"];
  if (occupation.totalAmount.value === 0) return ["text-success", "bg-success"];
  return ["triangle-muted", "bg-muted"];
}

/**
 * Returns the batch as StorageSpaceBatchInfo
 * @param batch the batch to take the information from
 * @returns {StorageSpaceBatchInfo} the batch as StorageSpaceBatchInfo
 */
export function getStorageSpaceBatchInfo(batch: Batch): StorageSpaceBatchInfo {
  return {
    _id: batch._id,
    lot: batch.lot,
    contentSnapshot: {
      _id: batch.content.details._id,
      title: batch.content.details.title,
      subtitle: batch.content.details.subtitle
    }
  };
}
