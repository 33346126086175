import { BSON } from "realm-web";
import { PersonSnapshot } from "./common.types";
import { BatchSnapshot, Outgoing } from "./outgoing.types";

export enum DeliveryState {
  OPEN = "open",
  DELIVERED = "delivered",
  CANCELED = "canceled"
}

export interface Delivery {
  _id: BSON.ObjectId;
  identifier: string; // 6 digit code
  created: Date;
  person: PersonSnapshot;
  state: DeliveryState;
  deliveries: Array<OutgoingSnapshot>;
  recipientText: string; // text under "Empfänger" in Modal
  title: string;
  positions: Array<PositionEntry>;
  additionalInformation: string;
  tracking?: TrackingInformation;
  eta: Date;
  deliveryDate?: Date;
  deliveryNote?: DeliveryFile;
  timeline: Array<DeliveryTimelineEntry>;
}

export interface OutgoingSnapshot
  extends Pick<
    Outgoing,
    "_id" | "batch" | "universalBarcode" | "movedAmount" | "movedPackagingUnits" | "movedReservation" | "destination"
  > {}

export interface DeliveryBatchSnapshot extends Pick<BatchSnapshot, "_id" | "lot"> {}

export interface PositionEntry {
  _id: BSON.ObjectId; // unique id, for references
  packagingUnits: string;
  title: string;
  description: string;
  batch: DeliveryBatchSnapshot | null; // null for "free positions"
  universalBarcode: string | null;
  isFreePosition?: boolean; // free positions can be deleted, others cannot
}

export interface TrackingInformation {
  trackingNumber: string;
  deliveryCompany: string;
  trackingLink: string;
}

export interface DeliveryFile {
  _id: BSON.ObjectId; // unique id, for references
  path: string;
  title: string;
  date: Date;
  person: PersonSnapshot;
}

export interface DeliveryTimelineEntry {
  _id: BSON.ObjectId;
  date: Date;
  person: PersonSnapshot;
  payload: DeliveryTimelineEntryPayload;
  diff: { pre: Partial<Delivery>; post: Partial<Delivery> };
}

export interface DeliveryTimelineEntryPayload {
  // TBD based on need
}
