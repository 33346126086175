import React, { useCallback, useRef, useState } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import i18n from "../../../translations/i18n";
import { DeliveryFile } from "../../../model/warehouse/delivery.types";

interface FileContextMenuProps {
  file: DeliveryFile | undefined;
  onDeleteDocument: () => void;
}

export const FileContextMenu: React.FC<FileContextMenuProps> = ({ file, onDeleteDocument }) => {
  const overlayRef: React.RefObject<HTMLSpanElement> = useRef(null);
  const [show, setShow] = useState(false);

  const handleShow = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    setShow(prevShow => !prevShow);
  }, []);

  const handleHide = useCallback(() => setShow(false), []);

  return (
    <>
      <span className="pointer" ref={overlayRef}>
        <img
          src={toAbsoluteUrl("/media/files/pdf.svg")}
          alt="pdf"
          style={file ? { height: 20 } : { height: 20, opacity: "25%" }}
          onClick={file ? handleShow : undefined}
        />
      </span>
      {file && (
        <Overlay rootClose show={show} onHide={handleHide} target={overlayRef.current} placement="bottom-start">
          <Popover id="dropdown-popover">
            <div className="dropdown-menu show" aria-labelledby="dropdownMenuLink">
              <a className="dropdown-item cursorhand" href={file.path} target="_blank">
                {i18n.t("warehouse:showDocument")}
              </a>
              <span className="dropdown-item cursorhand" onClick={onDeleteDocument}>
                {i18n.t("warehouse:deleteDocument")}
              </span>
            </div>
          </Popover>
        </Overlay>
      )}
    </>
  );
};
