import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { BaseActionModalProps } from "../../../model/warehouse/common.types";
import i18n from "../../../translations/i18n";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { Input } from "../../common/Input";
import { Delivery, DeliveryTimelineEntry, TrackingInformation } from "../../../model/warehouse/delivery.types";
import { useDataContext } from "../../../context/dataContext";
import baseUtils from "../../../utils/baseUtils";
import userService from "../../../services/userService";
import dbService, { DELIVERY, UpdateAction } from "../../../services/dbService";

const getEmptyTrackingInformation = (): TrackingInformation => {
  return {
    trackingNumber: "",
    deliveryCompany: "",
    trackingLink: ""
  };
};

const AddTrackingInformationModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const dataContext = useDataContext();
  const { delivery, updateDocumentInContext } = dataContext;

  const [saving, setSaving] = useState<boolean>(false);
  const [trackingInformation, setTrackingInformation] = useState<TrackingInformation>(getEmptyTrackingInformation());
  const { trackingNumber, trackingLink, deliveryCompany } = trackingInformation;

  const existingInformation: TrackingInformation | null = useMemo(() => {
    if (!actionTrigger?.deliveryId) return null;
    const deliveryDoc: Delivery | null = baseUtils.getDocFromCollection(delivery, actionTrigger.deliveryId);
    return deliveryDoc?.tracking || null;
  }, [delivery, actionTrigger?.deliveryId]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (_.isEqual(existingInformation, trackingInformation))
      errors.push(i18n.t("warehouse:trackingInformationSameInformationError"));
    if (Object.values(trackingInformation).every(i => !i.trim()))
      errors.push(i18n.t("warehouse:trackingInformationEmptyInformationError"));
    return errors;
  }, [existingInformation, trackingInformation]);

  useEffect(() => {
    if (show) {
      if (existingInformation) setTrackingInformation(existingInformation);
      else setTrackingInformation(getEmptyTrackingInformation());
    }
  }, [show]);

  const handleHide = () => {
    if (saving) return;
    onHide();
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value.trim();
    setTrackingInformation(prevInformation => ({ ...prevInformation, [name]: value }));
  };

  const handleSaveTrackingInformation = useCallback(async () => {
    setSaving(true);
    if (!actionTrigger?.deliveryId || _.isEqual(existingInformation, trackingInformation)) return;
    try {
      const timelineEntry: DeliveryTimelineEntry = {
        _id: new BSON.ObjectId(),
        date: new Date(),
        person: userService.getUserSnapshot(),
        payload: {},
        diff: {
          pre: existingInformation ? { tracking: existingInformation } : {},
          post: { tracking: trackingInformation }
        }
      };
      const action: UpdateAction = {
        collection: DELIVERY,
        filter: { _id: new BSON.ObjectId(actionTrigger.deliveryId) },
        update: { tracking: trackingInformation },
        push: { timeline: timelineEntry }
      };
      const result = await dbService.transaction([action]);
      if (result) {
        toast.success(i18n.t("warehouse:trackingInformationSuccess"));
        await updateDocumentInContext(DELIVERY, actionTrigger.deliveryId);
        onHide();
      } else {
        toast.error(i18n.t("warehouse:trackingInformationError"));
      }
    } finally {
      setSaving(false);
    }
  }, [existingInformation, trackingInformation, actionTrigger?.deliveryId]);

  return (
    <Modal show={show} onHide={handleHide} centered name={"trackingInformation"}>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:trackingInformation")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <div className="row">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:trackingInformationCompany")}
              </span>
            </div>
            <div className="col-12">
              <Input
                className="form-control"
                type="text"
                name={"deliveryCompany"}
                value={deliveryCompany}
                onChange={handleChangeText}
                placeholder={i18n.t("warehouse:trackingInformationCompanyPlaceholder")}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:trackingInformationNumber")}
              </span>
            </div>
            <div className="col-12">
              <Input
                className="form-control"
                type="text"
                name={"trackingNumber"}
                value={trackingNumber}
                onChange={handleChangeText}
                placeholder={i18n.t("warehouse:trackingInformationNumberPlaceholder")}
              />
            </div>
          </div>
          <div className="row mt-2 mb-4">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:trackingInformationLink")}
              </span>
            </div>
            <div className="col-12">
              <Input
                className="form-control"
                type="text"
                name={"trackingLink"}
                value={trackingLink}
                onChange={handleChangeText}
                placeholder={i18n.t("warehouse:trackingInformationLinkPlaceholder")}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          saving={saving}
          onClick={handleHide}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("common:save")}
          className={"btn btn-success"}
          errors={errors}
          saving={saving}
          onClick={handleSaveTrackingInformation}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddTrackingInformationModal;
