import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../../translations/i18n";
import { useWarehouseContext } from "../../../../context/warehouseContext";
import { useDataContext } from "../../../../context/dataContext";
import { resolveTranslation } from "../../../../utils/translationUtils";
import {
  DEFAULTWEIGHTUNIT,
  SelectedBatchEntryType,
  SelectedCommodityEntryType
} from "../../../../utils/warehouseUtils";
import {
  BaseActionModalProps,
  BatchStorageSpaceAssignment,
  LocationStorageSpaceAssignment,
  LocationType,
  PUStorageSpaceAssignmentRec,
  StorageSpaceBatchInfo,
  StorageSpaceOccupationsRec
} from "../../../../model/warehouse/common.types";
import { WarehouseTypes } from "../../../../model/configuration/warehouseConfiguration.types";
import { Batch, BatchLocation } from "../../../../model/warehouse/batch.types";
import {
  getStorageSpaceBatchInfo,
  getStorageSpaceSelectOptions,
  hasDuplicateLocations
} from "../../../../utils/warehouseStorageSpaceUtils";
import dbService, { BATCH } from "../../../../services/dbService";
import ErrorOverlayButton from "../../../common/ErrorOverlayButton";
import PackagingUnitEntry from "../../common/PackagingUnitEntry";
import { getNumericValue } from "../../../../utils/baseUtils";
import {
  getBatchIdsFromSelectedEntries,
  isSelectedBatchEntries,
  isSelectedCommodityEntries
} from "../../../../utils/warehouseActionUtils";
import { compareBatchLocations } from "../../../../utils/batchUtils";
import StorageSpaceRecommendations from "./StorageSpaceRecommendations";
import StorageSpaceAssignmentOverview from "./StorageSpaceAssignmentOverview";
import { addNumValueWeight } from "../../../../utils/warehouseCalculationUtils";

enum ViewAction {
  NEXT = "next",
  PREVIOUS = "previous"
}

enum View {
  ASSIGNMENT,
  SUMMARY
}

interface CurrentlyViewed {
  currentBatchNo: number;
  currentLocationNo: number;
}

interface AssignStorageSpaceModalState {
  view: View;
  currentlyViewed: CurrentlyViewed;
  currentPage: number;
  maxPage: number;
  batchAssignments: Array<BatchStorageSpaceAssignment>;
  currentBatch: StorageSpaceBatchInfo | undefined;
  currentLocation: BatchLocation | undefined;
  currentPUAssignment: PUStorageSpaceAssignmentRec | undefined;
  storageSpaceSearch: string;
  lastSelected: {
    packagingUnitId: string;
    index: number;
  };
}

const getDefaultState = (): AssignStorageSpaceModalState => {
  return {
    view: View.ASSIGNMENT,
    currentlyViewed: {
      currentBatchNo: 0,
      currentLocationNo: 0
    },
    currentPage: 1,
    maxPage: 1,
    batchAssignments: [],
    currentBatch: undefined,
    currentLocation: undefined,
    currentPUAssignment: undefined,
    storageSpaceSearch: "",
    lastSelected: {
      packagingUnitId: "",
      index: 0
    }
  };
};

const getInitialStorageSpaceAssignment = (newLocation: BatchLocation) => {
  const updatedData: PUStorageSpaceAssignmentRec = {};
  if (!newLocation.packagingUnits) return updatedData;

  newLocation.packagingUnits.forEach(packagingUnit => {
    const key = packagingUnit._id.toString();
    updatedData[key] = [
      {
        quantity: packagingUnit.quantity ?? 0,
        location: undefined,
        locationType: newLocation.location.storageSpace ? LocationType.STORAGESPACE : LocationType.PHYSICAL_WAREHOUSE
      }
    ];
  });
  return updatedData;
};

const AssignStorageSpaceModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { batch, updateDocumentInContext } = dataContext;
  const { selectedEntries, configuration } = warehouseContext;

  const [state, setState] = useState(getDefaultState());
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!show || saving || (selectedEntries.length === 0 && !actionTrigger)) return;
    const newState = getDefaultState();
    let selectedLocations: Array<string> = [];
    const batchEntries: Array<Batch> = [];

    // Modal was called from action trigger
    if (actionTrigger && actionTrigger.batchIds) {
      const batchObjects = batch.filter(b => actionTrigger.batchIds?.includes(b._id.toString()));
      if (batchObjects.length > 0) {
        for (let batchObj of batchObjects) {
          batchEntries.push(batchObj);
        }
        selectedLocations = actionTrigger.locationIds ?? [];
      }
    } else if (isSelectedBatchEntries(selectedEntries) || isSelectedCommodityEntries(selectedEntries)) {
      // Modal was called with selection via action panel
      const batchIds = getBatchIdsFromSelectedEntries(selectedEntries);
      if (batchIds && batchIds.length > 0) {
        for (let batchId of batchIds) {
          const batchEntry = batch.find(b => b._id.toString() === batchId);
          if (batchEntry) batchEntries.push(batchEntry);
        }
      }
      if (isSelectedBatchEntries(selectedEntries)) {
        selectedLocations = selectedEntries
          .filter(sE => sE.type === SelectedBatchEntryType.LOCATION)
          .map(sE => sE.locationId!);
      } else if (isSelectedCommodityEntries(selectedEntries)) {
        selectedLocations = selectedEntries
          .filter(sE => sE.type === SelectedCommodityEntryType.BATCH_LOCATION)
          .map(sE => sE.locationId!);
      }
    }
    if (batchEntries.length === 0 || selectedLocations.length === 0) {
      setState(newState);
      return;
    }
    // Get relevant locations (directly managed and in selected locations)
    for (let batchEntry of batchEntries) {
      const locationAssignments: Array<LocationStorageSpaceAssignment> = [];
      const batchInfo = getStorageSpaceBatchInfo(batchEntry);
      const relevantLocations = batchEntry.locations.filter(
        l =>
          l.location.warehouseArea.type === WarehouseTypes.DIRECTLYMANAGED &&
          selectedLocations.includes(l._id.toString())
      );
      for (let location of relevantLocations) {
        const locationStorageSpaceAssignment = getInitialStorageSpaceAssignment(location);
        const locationInfo: LocationStorageSpaceAssignment = {
          batchLocation: location,
          pUAssignment: locationStorageSpaceAssignment
        };
        locationAssignments.push(locationInfo);
      }
      if (locationAssignments.length > 0) {
        newState.batchAssignments.push({
          batchInfo,
          locationAssignments
        });
      }
    }
    if (newState.batchAssignments.length > 0) {
      // "cache" values in state so those can be used as dependencies instead of batchAssignments
      // to avoid having to recalculate everything on storage space change
      newState.currentBatch = newState.batchAssignments[0].batchInfo;
      newState.currentLocation = newState.batchAssignments[0].locationAssignments[0].batchLocation;
      newState.currentPUAssignment = newState.batchAssignments[0].locationAssignments[0].pUAssignment;
      newState.maxPage = newState.batchAssignments.flatMap(bA => bA.locationAssignments).length;
    }
    setState(newState);
  }, [show, batch, selectedEntries, actionTrigger, saving]);

  const batchAssignmentOverview = useMemo(() => {
    // Only calculate in last step
    if (state.view !== View.SUMMARY) return;
    const reducedBatchAssignments: Array<BatchStorageSpaceAssignment> = [];
    for (let batchAssignment of state.batchAssignments) {
      const newLocationAssignment: Array<LocationStorageSpaceAssignment> = [];
      for (let locationAssignment of batchAssignment.locationAssignments) {
        const { batchLocation, pUAssignment } = locationAssignment;
        const newPUAssignment: PUStorageSpaceAssignmentRec = {};
        for (const [pUId, pUStorageSpaceAssignment] of Object.entries(pUAssignment)) {
          const filteredAssignments = pUStorageSpaceAssignment.filter(
            stSpA =>
              stSpA.location &&
              stSpA.quantity > 0 &&
              stSpA.location !==
                (batchLocation.location.storageSpace?._id.toString() ??
                  batchLocation.location.warehouseArea._id.toString())
          );
          if (filteredAssignments.length > 0) {
            newPUAssignment[pUId] = filteredAssignments;
          }
        }
        if (Object.keys(newPUAssignment).length > 0) {
          newLocationAssignment.push({
            batchLocation,
            pUAssignment: newPUAssignment
          });
        }
      }
      if (newLocationAssignment.length > 0) {
        reducedBatchAssignments.push({
          batchInfo: batchAssignment.batchInfo,
          locationAssignments: newLocationAssignment
        });
      }
    }
    return reducedBatchAssignments.length > 0 ? reducedBatchAssignments : undefined;
  }, [state.batchAssignments, state.view]);

  const currentPhysicalWarehouse = useMemo(() => {
    const { currentLocation } = state;
    const warehouses = configuration?.values.warehouseStructure;
    return warehouses && currentLocation
      ? warehouses
          .flatMap(w => w.physicalWarehouses)
          .find(pW => pW._id.toString() === currentLocation.location.warehouseArea._id.toString())
      : undefined;
  }, [state.currentLocation, configuration?.values.warehouseStructure]);

  const currentLocationName = useMemo(
    () => state.currentLocation?.location.storageSpace?.storageSpaceNo ?? i18n.t("warehouse:incomingTab"),
    [state.currentLocation]
  );

  const storageSpaceOccupations = useMemo(() => {
    const { currentLocation } = state;
    if (!currentLocation || !currentPhysicalWarehouse) return undefined;
    const occupation: StorageSpaceOccupationsRec = {};

    // Create entries for all existing storage spaces of the current physical warehouse, including its entrance
    const entranceKey = currentPhysicalWarehouse._id.toString();
    occupation[entranceKey] = {
      locationSnapshot: {
        locationName: i18n.t("warehouse:incomingTab"),
        warehouseAreaId: currentPhysicalWarehouse._id.toString()
      },
      batchList: new Set<string>(),
      contentList: new Set<string>(),
      totalAmount: { value: 0, unit: DEFAULTWEIGHTUNIT }
    };
    if (currentPhysicalWarehouse.storageSpaces && currentPhysicalWarehouse.storageSpaces.length > 0) {
      for (let storageSpace of currentPhysicalWarehouse.storageSpaces) {
        const storageSpaceKey = `${currentPhysicalWarehouse._id.toString()}_${storageSpace._id.toString()}`;
        occupation[storageSpaceKey] = {
          locationSnapshot: {
            locationName: storageSpace.storageSpaceNo,
            warehouseAreaId: currentPhysicalWarehouse._id.toString(),
            storageSpaceId: storageSpace._id.toString(),
            dimensions: storageSpace.dimensions,
            maxWeight: storageSpace.maxWeight
          },
          batchList: new Set<string>(),
          contentList: new Set<string>(),
          totalAmount: { value: 0, unit: DEFAULTWEIGHTUNIT }
        };
      }
    }

    // Check for each storage space which batches and which commodities are located there, and how much is stored there in total
    for (let batchObj of batch) {
      const batchId = batchObj._id.toString();
      const contentId = batchObj.content.details._id.toString();
      for (let locationObj of batchObj.locations) {
        const { location, amountAtLocation } = locationObj;
        // Only include locations from the current physical warehouse
        if (!compareBatchLocations(location, currentLocation.location, true)) continue;
        const batchLocationKey =
          location.warehouseArea._id.toString() +
          (location.storageSpace ? `_${location.storageSpace?._id.toString()}` : "");
        if (occupation[batchLocationKey]) {
          const newTotalAmount = addNumValueWeight(
            DEFAULTWEIGHTUNIT,
            occupation[batchLocationKey].totalAmount,
            amountAtLocation
          );
          occupation[batchLocationKey].batchList.add(batchId);
          occupation[batchLocationKey].contentList.add(contentId);
          occupation[batchLocationKey].totalAmount = newTotalAmount ?? occupation[batchLocationKey].totalAmount;
        }
      }
    }
    return occupation;
  }, [batch, state.currentLocation, currentPhysicalWarehouse]);

  const storageSpaceSelection = useMemo(
    () => getStorageSpaceSelectOptions(currentPhysicalWarehouse),
    [currentPhysicalWarehouse]
  );

  const locationErrors = useMemo(() => {
    const { currentLocation, currentPUAssignment } = state;
    const errors = new Set<string>();
    if (!currentLocation || !currentPUAssignment) {
      errors.add(i18n.t("warehouse:storageSpaceAssignmentError"));
      return Array.from(errors);
    }
    for (let i = 0; i < currentLocation.packagingUnits.length; i++) {
      const pUId = currentLocation.packagingUnits[i]._id.toString();
      const pUQuantity = currentLocation.packagingUnits[i].quantity;
      const storageSpaceAssignmentEntry = currentPUAssignment[pUId];
      if (storageSpaceAssignmentEntry) {
        const totalAmount = storageSpaceAssignmentEntry.reduce((acc, currentEntry) => acc + currentEntry.quantity, 0);
        if (totalAmount !== pUQuantity) {
          errors.add(i18n.t("warehouse:storageSpaceAssignmentQuantityError"));
        }
        const duplicateLocations = hasDuplicateLocations(storageSpaceAssignmentEntry);
        if (duplicateLocations) errors.add(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
      }
    }
    return Array.from(errors);
  }, [state.currentLocation, state.currentPUAssignment]);

  const globalErrors = useMemo(() => {
    const errors = new Set<string>();
    if (!batchAssignmentOverview) errors.add(i18n.t("warehouse:storageSpaceAssignmentNoChangeError"));
    return Array.from(errors);
  }, [batchAssignmentOverview]);

  const handleLocationChange = useCallback(
    (direction: ViewAction) => {
      setState(prevState => {
        const { currentlyViewed, batchAssignments, view, currentPage } = prevState;
        const { currentBatchNo, currentLocationNo } = currentlyViewed;
        // Check if first batch and first location; If yes, do nothing (for safety)
        if (
          view === View.ASSIGNMENT &&
          currentBatchNo === 0 &&
          currentLocationNo === 0 &&
          direction === ViewAction.PREVIOUS
        )
          return prevState;
        let newView = view;
        let newPage = currentPage;
        const newCurrentlyViewed = { ...currentlyViewed };
        const newBatchAssignments = [...batchAssignments];
        const newLastSelected = {
          packagingUnitId: "",
          index: 0
        };
        const newStorageSpaceSearch = "";
        const numberBatches = batchAssignments.length;
        const currentBatch = batchAssignments[currentBatchNo];
        const numberCurrentBatchLocations = currentBatch.locationAssignments.length;
        if (direction === ViewAction.NEXT) {
          if (currentBatchNo === numberBatches - 1 && currentLocationNo === numberCurrentBatchLocations - 1) {
            // Check if last batch and last location; If yes, move on to summary
            newView = View.SUMMARY;
          } else if (currentLocationNo + 1 === numberCurrentBatchLocations) {
            // Check if last location in batch; If yes, move to next batch
            newCurrentlyViewed.currentBatchNo = currentBatchNo + 1;
            newCurrentlyViewed.currentLocationNo = 0;
          } else {
            // Move to next location
            newCurrentlyViewed.currentLocationNo = currentLocationNo + 1;
          }
          newPage++;
        } else if (direction === ViewAction.PREVIOUS) {
          if (view === View.SUMMARY) {
            newView = View.ASSIGNMENT;
          } else if (currentLocationNo === 0) {
            // Check if first location in batch; If yes, move to previous batch
            newCurrentlyViewed.currentBatchNo = currentBatchNo - 1;
            newCurrentlyViewed.currentLocationNo = batchAssignments[currentBatchNo - 1].locationAssignments.length - 1;
          } else {
            // Move to previous location
            newCurrentlyViewed.currentLocationNo = currentLocationNo - 1;
          }
          newPage--;
        }
        if (state.currentPUAssignment) {
          newBatchAssignments[currentBatchNo].locationAssignments[currentLocationNo].pUAssignment =
            state.currentPUAssignment;
        }
        const newCurrentBatch = batchAssignments[newCurrentlyViewed.currentBatchNo].batchInfo;
        const newCurrentLocation =
          batchAssignments[newCurrentlyViewed.currentBatchNo].locationAssignments[newCurrentlyViewed.currentLocationNo]
            .batchLocation;
        const newCurrentPUAssignment =
          batchAssignments[newCurrentlyViewed.currentBatchNo].locationAssignments[newCurrentlyViewed.currentLocationNo]
            .pUAssignment;

        return {
          ...prevState,
          view: newView,
          currentlyViewed: newCurrentlyViewed,
          currentPage: newPage,
          batchAssignments: newBatchAssignments,
          currentBatch: newCurrentBatch,
          currentLocation: newCurrentLocation,
          currentPUAssignment: newCurrentPUAssignment,
          storageSpaceSearch: newStorageSpaceSearch,
          lastSelected: newLastSelected
        };
      });
    },
    [state.currentPUAssignment]
  );

  const handleAddRow = useCallback(
    (packagingUnitId: string) => {
      if (!state.currentPUAssignment) return;
      const rows = _.cloneDeep(state.currentPUAssignment);
      rows[packagingUnitId].push({ quantity: 0, location: undefined, locationType: LocationType.STORAGESPACE });
      setState(prevState => ({
        ...prevState,
        currentPUAssignment: rows
      }));
    },
    [state.currentPUAssignment]
  );

  const handleDeleteRow = useCallback(
    (packagingUnitId: string, index: number) => {
      const { currentPUAssignment, lastSelected } = state;
      if (!currentPUAssignment) return;
      const rows = _.cloneDeep(currentPUAssignment);
      let updatedLastSelected = lastSelected;
      rows[packagingUnitId].splice(index, 1);
      if (lastSelected.packagingUnitId === packagingUnitId && lastSelected.index > rows[packagingUnitId].length - 1) {
        updatedLastSelected = {
          packagingUnitId: "",
          index: 0
        };
      }
      setState(prevState => ({
        ...prevState,
        currentPUAssignment: rows,
        lastSelected: updatedLastSelected
      }));
    },
    [state.currentPUAssignment, state.lastSelected]
  );

  const handleChangeAmount = useCallback(
    (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
      if (!state.currentPUAssignment) return;
      const rows = _.cloneDeep(state.currentPUAssignment);
      const value = getNumericValue(e);
      const entry = rows[packagingUnitId][index];
      entry.quantity = Number(value);
      setState(prevState => ({
        ...prevState,
        currentPUAssignment: rows
      }));
    },
    [state.currentPUAssignment]
  );

  const handeChangeLocation = useCallback(
    (packagingUnitId: string, index: number, value?: any) => {
      if (!state.currentPUAssignment) return;
      const rows = _.cloneDeep(state.currentPUAssignment);
      const entry = rows[packagingUnitId][index];
      entry.location = value && value.length > 0 ? value[0].value : undefined;
      entry.locationType = value && value.length > 0 ? value[0].data : LocationType.STORAGESPACE;
      setState(prevState => ({
        ...prevState,
        storageSpaceSearch: "",
        currentPUAssignment: rows
      }));
    },
    [state.currentPUAssignment]
  );

  const handleBlurLocation = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setState(prevState => ({
      ...prevState,
      storageSpaceSearch: value
    }));
  }, []);

  const handleFocusLocation = useCallback((packagingUnitId: string, index: number) => {
    setState(prevState => ({
      ...prevState,
      lastSelected: {
        packagingUnitId,
        index
      }
    }));
  }, []);

  const handleSelectRecommendation = useCallback(
    (storageSpaceId: string) => {
      const { lastSelected, currentPUAssignment } = state;
      if (!currentPUAssignment) return;
      const rows = _.cloneDeep(currentPUAssignment);
      // If no input was selected, set chosen recommended location for all inputs
      if (!lastSelected.packagingUnitId) {
        for (const key in rows) {
          for (let stSpAssignment of rows[key]) {
            stSpAssignment.location = storageSpaceId;
            stSpAssignment.locationType = LocationType.STORAGESPACE;
          }
        }
      } else {
        // Otherwise set only for last selected input field
        const entry = rows[lastSelected.packagingUnitId][lastSelected.index];
        entry.location = storageSpaceId;
        entry.locationType = LocationType.STORAGESPACE;
      }
      setState(prevState => ({
        ...prevState,
        currentPUAssignment: rows
      }));
    },
    [state.currentPUAssignment, state.lastSelected]
  );

  const handleSave = useCallback(async () => {
    if (state.view !== View.SUMMARY || !batchAssignmentOverview || batchAssignmentOverview.length === 0) return;
    setSaving(true);
    try {
      const results = await dbService.callFunction<Array<boolean>>(
        "assignMultipleStorageSpaces",
        [batchAssignmentOverview],
        true
      );
      if (results.length > 0 && results.every(r => r)) {
        toast.success(i18n.t("warehouse:storageSpaceAssignmentSuccess"));
        for (let i = 0; i < batchAssignmentOverview.length; i++) {
          await updateDocumentInContext(BATCH, batchAssignmentOverview[i].batchInfo._id);
        }
        onHide();
      } else {
        toast.error(i18n.t("warehouse:storageSpaceAssignmentFailure"));
      }
    } catch (e) {
      console.error(e);
      toast.error(`${i18n.t("common:unexpectedError")}: ${e.message}`);
    } finally {
      setSaving(false);
    }
  }, [state.view, batchAssignmentOverview]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:assignStorageSpace")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {state.view === View.ASSIGNMENT && (
          <>
            {state.batchAssignments.length > 0 && state.currentBatch ? (
              <div className="px-2">
                <div className="kt-portlet__body">
                  <div className="row">
                    <div className="col-10">
                      <span className="kt-font-bold kt-font-dark">
                        {resolveTranslation(state.currentBatch.contentSnapshot.title)}
                      </span>
                      <span className="kt-font-dark ml-2">
                        {i18n.t("warehouse:lot")}: {state.currentBatch.lot}
                      </span>
                    </div>
                    <div className="col-2 kt-font-dark">
                      <small className="float-right">{`${i18n.t("common:page")} ${state.currentPage}/${
                        state.maxPage
                      }`}</small>
                    </div>
                    <div className="col-12 kt-font-bold kt-font-dark">
                      <small className="kt-font-regular">
                        {resolveTranslation(state.currentBatch.contentSnapshot.subtitle)}
                      </small>
                    </div>
                  </div>
                  {state.currentLocation ? (
                    <>
                      <div className="row">
                        <div className="col-12">
                          <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-2">
                            <li className="breadcrumb-item text-black">
                              {resolveTranslation(state.currentLocation.location.warehouseSnapshot.warehouseName)}
                            </li>
                            <li className="breadcrumb-item text-black">
                              {resolveTranslation(state.currentLocation.location.warehouseArea.warehouseName)}
                            </li>
                            <li className="breadcrumb-item text-danger font-weight-bolder arrow">
                              {currentLocationName}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {state.currentPUAssignment && (
                        <>
                          {state.currentLocation.packagingUnits.map(pU => {
                            return (
                              <PackagingUnitEntry
                                key={pU._id.toString()}
                                pU={pU}
                                currentLocationName={currentLocationName}
                                storageSpaceAssignment={state.currentPUAssignment![pU._id.toString()]}
                                selectOptions={storageSpaceSelection}
                                storageSpaceOccupations={storageSpaceOccupations}
                                onAddRow={handleAddRow}
                                onDeleteRow={handleDeleteRow}
                                onChangeAmount={handleChangeAmount}
                                onChangeLocation={handeChangeLocation}
                                onBlurLocation={handleBlurLocation}
                                onFocusLocation={handleFocusLocation}
                              />
                            );
                          })}
                        </>
                      )}
                      <StorageSpaceRecommendations
                        storageSpaceOccupations={storageSpaceOccupations}
                        storageSpaceSearch={state.storageSpaceSearch}
                        batch={state.currentBatch}
                        onSelectRecommendation={handleSelectRecommendation}
                      />
                    </>
                  ) : (
                    <div className="text-muted mt-3">{i18n.t("warehouse:storageSpaceAssignmentWarehouseError")}</div>
                  )}
                </div>
              </div>
            ) : (
              <div className="text-muted">{i18n.t("warehouse:storageSpaceAssignmentWarehouseError")}</div>
            )}
          </>
        )}
        {state.view === View.SUMMARY && (
          <StorageSpaceAssignmentOverview
            batchAssignments={batchAssignmentOverview}
            selectOptions={storageSpaceSelection}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={"btn btn-secondary " + (saving ? "disabled" : "")}
          onClick={
            saving
              ? undefined
              : state.view === View.ASSIGNMENT &&
                state.currentlyViewed.currentLocationNo === 0 &&
                state.currentlyViewed.currentBatchNo === 0
              ? onHide
              : () => handleLocationChange(ViewAction.PREVIOUS)
          }
        >
          {i18n.t(
            `common:${
              state.view === View.ASSIGNMENT &&
              state.currentlyViewed.currentLocationNo === 0 &&
              state.currentlyViewed.currentBatchNo === 0
                ? "close"
                : "back"
            }`
          )}
        </button>
        <ErrorOverlayButton
          buttonText={i18n.t(`common:${state.view === View.ASSIGNMENT ? "next" : "save"}`)}
          className={"btn btn-success " + (saving ? "disabled" : "")}
          errors={state.view === View.ASSIGNMENT ? locationErrors : globalErrors}
          saving={saving}
          onClick={state.view === View.ASSIGNMENT ? () => handleLocationChange(ViewAction.NEXT) : handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AssignStorageSpaceModal;
