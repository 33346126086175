import React, { useCallback, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import i18n from "../../../translations/i18n";
import { PackagingUnit } from "../../../model/warehouse/batch.types";
import {
  LocationType,
  PUStorageSpaceAssignment,
  StorageSpaceOccupationsRec
} from "../../../model/warehouse/common.types";
import { SelectOption } from "../../../model/common.types";
import { getStorageSpaceOccupationColor, hasDuplicateLocations } from "../../../utils/warehouseStorageSpaceUtils";
import { getFormattedPackagingUnitAmount } from "../../../utils/warehouseUtils";
import { resolveTranslation } from "../../../utils/translationUtils";
import baseUtils, { formatNumValueLocale } from "../../../utils/baseUtils";

interface PackagingUnitEntryProps {
  pU: PackagingUnit;
  currentLocationName: string;
  storageSpaceAssignment?: Array<PUStorageSpaceAssignment>;
  selectOptions: Array<SelectOption<LocationType>>;
  storageSpaceOccupations?: StorageSpaceOccupationsRec;
  additionalWarnings?: Array<string>;
  forWarehouse?: boolean;
  onAddRow: (packagingUnitId: string) => void;
  onDeleteRow: (packagingUnitId: string, index: number) => void;
  onChangeAmount: (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (packagingUnitId: string, index: number, value?: any) => void;
  onBlurLocation?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocusLocation?: (packagingUnitId: string, index: number) => void;
}

const PackagingUnitEntry: React.FC<PackagingUnitEntryProps> = ({
  pU,
  currentLocationName,
  storageSpaceAssignment,
  selectOptions,
  storageSpaceOccupations,
  additionalWarnings,
  forWarehouse,
  onAddRow,
  onDeleteRow,
  onChangeAmount,
  onChangeLocation,
  onBlurLocation,
  onFocusLocation
}) => {
  const warnings = useMemo(() => {
    const warnings: Array<string> = [];
    const selectedAmount = storageSpaceAssignment
      ? storageSpaceAssignment.reduce((acc, currentAssignment) => {
          return acc + currentAssignment.quantity;
        }, 0)
      : undefined;
    const sameDestination = storageSpaceAssignment ? hasDuplicateLocations(storageSpaceAssignment) : false;
    const diff = pU.quantity !== null && selectedAmount !== undefined ? pU.quantity - selectedAmount : undefined;
    if (diff && diff > 0) {
      warnings.push(i18n.t("warehouse:storageSpaceAssignmentTooFewPUError", { amount: diff }));
    } else if (diff && diff < 0) {
      warnings.push(i18n.t("warehouse:storageSpaceAssignmentTooManyPUError", { amount: Math.abs(diff) }));
    }
    if (sameDestination) warnings.push(i18n.t("warehouse:storageSpaceAssignmentLocationError"));
    return warnings;
  }, [storageSpaceAssignment, pU]);

  return (
    <>
      <hr />
      <div className="row my-2">
        <div className="col-12 text-black">{getFormattedPackagingUnitAmount(pU, true, true, true)}</div>
      </div>
      {storageSpaceAssignment ? (
        <>
          {storageSpaceAssignment.map((stA, index) => (
            <PackingUnitEntryInputs
              key={index}
              pU={pU}
              currentLocationName={currentLocationName}
              storageSpaceAssignment={stA}
              selectOptions={selectOptions}
              index={index}
              storageSpaceOccupations={storageSpaceOccupations}
              onDeleteRow={onDeleteRow}
              onChangeAmount={onChangeAmount}
              onChangeLocation={onChangeLocation}
              onBlurLocation={onBlurLocation}
              onFocusLocation={onFocusLocation}
            />
          ))}
          <div className="row mt-2">
            <div className="col-11 text-warning">{warnings.join(", ")}</div>
            {additionalWarnings && <div className="col-11 text-warning">{additionalWarnings.join(", ")}</div>}
            <div className="col-1 text-right pr-0">
              <button
                className={
                  "btn btn-success px-2 py-1 " +
                  (!pU.quantity || storageSpaceAssignment.length >= pU.quantity ? "disabled" : "")
                }
                onClick={
                  !pU.quantity || storageSpaceAssignment.length >= pU.quantity
                    ? undefined
                    : () => onAddRow(pU._id.toString())
                }
              >
                <i className="fa fa-plus p-0" />
              </button>
            </div>
          </div>
        </>
      ) : (
        <div>
          {forWarehouse
            ? i18n.t("warehouse:noWarehouseToAssignError")
            : i18n.t("warehouse:storageSpaceAssignmentError")}
        </div>
      )}
    </>
  );
};

interface PackingUnitEntryInputsProps {
  pU: PackagingUnit;
  currentLocationName: string;
  storageSpaceAssignment: PUStorageSpaceAssignment;
  selectOptions: Array<SelectOption<LocationType>>;
  index: number;
  storageSpaceOccupations?: StorageSpaceOccupationsRec;
  onDeleteRow: (packagingUnitId: string, index: number) => void;
  onChangeAmount: (packagingUnitId: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLocation: (packagingUnitId: string, index: number, value?: any) => void;
  onBlurLocation?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocusLocation?: (packagingUnitId: string, index: number) => void;
}

const PackingUnitEntryInputs: React.FC<PackingUnitEntryInputsProps> = ({
  pU,
  currentLocationName,
  storageSpaceAssignment,
  selectOptions,
  index,
  storageSpaceOccupations,
  onDeleteRow,
  onChangeAmount,
  onChangeLocation,
  onBlurLocation,
  onFocusLocation
}) => {
  const storageSpaceSelect = useMemo(
    () => selectOptions.find(sto => sto.value === storageSpaceAssignment.location),
    [selectOptions, storageSpaceAssignment]
  );

  const physicalWarehouseId = useMemo(
    () => selectOptions.find(sto => sto.data === LocationType.PHYSICAL_WAREHOUSE)?.value,
    [selectOptions]
  );

  const storageSpaceOccupation = useMemo(() => {
    if (!storageSpaceOccupations || !storageSpaceAssignment.location || !physicalWarehouseId) return undefined;
    const searchKey =
      storageSpaceAssignment.locationType === LocationType.PHYSICAL_WAREHOUSE
        ? storageSpaceAssignment.location
        : `${physicalWarehouseId}_${storageSpaceAssignment.location}`;
    return storageSpaceOccupations[searchKey];
  }, [storageSpaceOccupations, storageSpaceAssignment, physicalWarehouseId]);

  const storageSpaceOccupationColor = useMemo(
    () => getStorageSpaceOccupationColor(storageSpaceOccupation)[0],
    [storageSpaceOccupation]
  );

  const handleChangeAmount = useCallback(
    e => onChangeAmount(pU._id.toString(), index, e),
    [onChangeAmount, pU._id, index]
  );

  const handleChangeLocation = useCallback(
    (value: any) => onChangeLocation(pU._id.toString(), index, value),
    [onChangeLocation, pU._id, index]
  );

  const handleFocusLocation = useCallback(
    () => (onFocusLocation ? onFocusLocation(pU._id.toString(), index) : undefined),
    [onFocusLocation, pU._id, index]
  );

  const handleDeleteRow = useCallback(
    () => (index === 0 ? undefined : onDeleteRow(pU._id.toString(), index)),
    [index, onDeleteRow, pU._id]
  );

  return (
    <div className="row align-items-center mt-2">
      <div className="col-11 px-1">
        <div className="input-group input-group-solid">
          <div className="input-group-prepend" style={{ width: "35%" }}>
            <input
              className="form-control form-control-solid border-0"
              type="number"
              placeholder={i18n.t("common:quantity")}
              min={0}
              max={pU.quantity ?? undefined}
              step={1}
              name="pUAmount"
              value={storageSpaceAssignment.quantity}
              onChange={handleChangeAmount}
            />
            <span className="input-group-text input-group-solid-dark border-0">
              {baseUtils.truncateString(resolveTranslation(pU.puSnapshot.label), 4)}
            </span>
          </div>
          <span style={{ width: "55%" }}>
            <Typeahead
              id="storageSpace"
              inputProps={{ className: "form-control form-control-solid text-black w-100" }}
              labelKey="label"
              highlightOnlyResult={true}
              options={selectOptions}
              placeholder={currentLocationName}
              selected={storageSpaceSelect ? [storageSpaceSelect] : []}
              onBlur={onBlurLocation}
              onChange={handleChangeLocation}
              onFocus={handleFocusLocation}
            />
          </span>
          <div className="input-group-append justify-content-center flex-grow-1" style={{ width: "10%" }}>
            {storageSpaceOccupation ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="occupation">
                    <span>{i18n.t("warehouse:storageSpaceOccupation")} </span>
                    <span className="font-weight-bold">{formatNumValueLocale(storageSpaceOccupation.totalAmount)}</span>
                  </Tooltip>
                }
              >
                <span className="input-group-text border-0 flex-grow-1 input-group-solid-dark">
                  <i className={`fas fa-exclamation-triangle ${storageSpaceOccupationColor}`} />
                </span>
              </OverlayTrigger>
            ) : (
              <span className="input-group-text border-0 flex-grow-1 input-group-solid-dark">
                <i className={`fas fa-exclamation-triangle ${storageSpaceOccupationColor}`} />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="col-1">
        <button className={"btn btn-danger px-2 py-1 " + (index === 0 ? "disabled" : "")} onClick={handleDeleteRow}>
          <i className="fa fa-trash p-0" />
        </button>
      </div>
    </div>
  );
};

export default PackagingUnitEntry;
