import _ from "lodash";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { WarehouseConfiguration } from "../model/configuration/warehouseConfiguration.types";
import { getConfiguration } from "../utils/configurationUtils";
import { ProductFilterValuesEnum } from "../utils/warehouseFilterUtils";
import { Batch } from "../model/warehouse/batch.types";
import { useDataContext } from "./dataContext";
import baseUtils from "../utils/baseUtils";
import {
  SelectedBatchEntryType,
  SelectedCommodityEntryType,
  SelectedDeliveryAnnouncementEntryType,
  SelectedDeliveryEntryType,
  SelectedDestinationEntryType,
  SelectedOrderEntryType,
  SelectedReservationEntryType
} from "../utils/warehouseUtils";
import {
  updateSelectedBatchEntries,
  updateSelectedCommodityEntries,
  updateSelectedDeliveryAnnouncements,
  updateSelectedDeliveryEntries,
  updateSelectedDestinationEntries,
  updateSelectedOrderEntries,
  updateSelectedReservationEntries
} from "../utils/warehouseActionUtils";
import dbService from "../services/dbService";
import { ConfigurationKeys } from "../model/configuration/configuration.types";
import { TabDocument } from "../model/warehouse/customTypes.types";
import { ActionTrigger } from "../model/warehouse/common.types";
import { getUpdatedSelectedEntries, SelectTabPayload } from "../utils/warehouseContextUtils";

/**
 * Enums
 */

export enum WarehouseListingTabNames {
  RAW_MATERIAL = "rawMaterial",
  BATCHES = "batches",
  AVIS = "avis",
  INCOMING = "incoming",
  AVAILABLE = "available",
  RESERVED = "reserved",
  ORDERS = "orders",
  OUTGOING = "outgoing",
  TRANSFER = "transfer"
}

/**
 * Interfaces
 */

export interface WarehouseLocation {
  warehouse: string;
  warehouseArea?: string;
}

export interface AdditionalProductFilter {
  organic: boolean;
  providedByCustomer: boolean;
}

export interface SelectedBatchEntry {
  type: SelectedBatchEntryType; // batch or location
  batchId: string; // id of batch
  locationId?: string; // given if type location, id of location
  numberOfChildren: number; // how many children are beneath the parent batch
  currentChildren?: Array<string>; // given if type batch, id of locations shown in current tab under that batch
}

export interface SelectedCommodityEntry {
  type: SelectedCommodityEntryType; // commodity or location
  commodityId: string;
  batchId?: string;
  locationId?: string;
  numberOfChildren: number;
  currentChildren?: Array<[batchId: string, locationId: string]>;
}

export interface SelectedDeliveryAnnouncementEntry {
  type: SelectedDeliveryAnnouncementEntryType;
  deliveryAnnouncementId: string;
  childId: string;
  numberOfChildren: number;
}

export interface SelectedOrderEntry {
  type: SelectedOrderEntryType;
  orderId: string;
  recipeCommodityId?: string;
  numberOfChildren: number;
  currentChildren?: Array<string>;
}

export interface SelectedDestinationEntry {
  type: SelectedDestinationEntryType;
  destinationId: string;
  batchId?: string;
  packagingUnitId?: string;
  numberOfChildren: number;
  currentChildren?: Array<{ batchId: string; packagingUnitId: string }>;
}

export interface SelectedDeliveryEntry {
  type: SelectedDeliveryEntryType;
  deliveryId: string;
}

export interface SelectedReservationEntry {
  type: SelectedReservationEntryType;
  reservationId: string;
  locationId: string | undefined;
  materialId: string;
}

/**
 * Types
 */

export type SelectedEntryType =
  | SelectedBatchEntry
  | SelectedCommodityEntry
  | SelectedDeliveryAnnouncementEntry
  | SelectedDestinationEntry
  | SelectedDeliveryEntry
  | SelectedOrderEntry
  | SelectedReservationEntry;

/**
 * Context definition
 */

export interface WarehouseContext {
  configuration: WarehouseConfiguration | null;
  selectedLocation: WarehouseLocation | null;
  productFilter: string;
  additionalProductFilter: AdditionalProductFilter;
  informationPanelBatch: Batch | null;
  selectedEntries: Array<SelectedEntryType>;
  activeTab: WarehouseListingTabNames;
  actionTrigger: ActionTrigger;
  query: string;
  warehouseListingTabDocuments: Array<TabDocument>;
}

const defaultWarehouseContext: WarehouseContext = {
  configuration: null,
  selectedLocation: null,
  productFilter: ProductFilterValuesEnum.ALL,
  additionalProductFilter: { organic: false, providedByCustomer: false },
  informationPanelBatch: null,
  selectedEntries: [],
  activeTab: WarehouseListingTabNames.RAW_MATERIAL,
  actionTrigger: { actionNumber: null },
  query: "",
  warehouseListingTabDocuments: []
};

const defaultWarehouseDispatchContext: React.Dispatch<WarehouseAction> = () => {};

const WarehouseContext = createContext<WarehouseContext>(defaultWarehouseContext);
const WarehouseDispatchContext = createContext<React.Dispatch<WarehouseAction>>(defaultWarehouseDispatchContext);

/**
 * Provider component
 */

interface WarehouseProviderProps {
  children: React.ReactNode;
}

export const WarehouseProvider: React.FC<WarehouseProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(warehouseReducer, defaultWarehouseContext);
  const { batch } = useDataContext();

  /**
   * Handle updates on warehouse related context
   * @param change change object from stream
   */
  const updateWarehouseContext = async (change: Realm.Services.MongoDB.UpdateEvent<WarehouseConfiguration>) => {
    if (change.fullDocument)
      dispatch({ type: WarehouseActionType.CONFIGURATION_LOADED, payload: { configuration: change.fullDocument } });
  };

  /**
   * Load warehouse configuration
   */
  useEffect(() => {
    async function loadConfiguration() {
      try {
        const warehouseConfiguration = await getConfiguration<WarehouseConfiguration>(ConfigurationKeys.WAREHOUSE);
        if (warehouseConfiguration) {
          dispatch({
            type: WarehouseActionType.CONFIGURATION_LOADED,
            payload: { configuration: warehouseConfiguration }
          });
        }
        dbService.listenerWarehouseContext(updateWarehouseContext);
      } catch (e) {
        console.error(e);
      }
    }
    loadConfiguration();
  }, []);

  /**
   * Handle updates on the batch selected for the information panel
   */
  useEffect(() => {
    if (state.informationPanelBatch) {
      const batchFromDataContext = baseUtils.getDocFromCollection(batch, state.informationPanelBatch._id);
      if (!_.isEqual(batchFromDataContext, state.informationPanelBatch))
        dispatch({
          type: WarehouseActionType.SELECT_BATCH,
          payload: { batch: batchFromDataContext || null }
        });
    }
  }, [batch]);

  return (
    <WarehouseContext.Provider value={state}>
      <WarehouseDispatchContext.Provider value={dispatch}>{children}</WarehouseDispatchContext.Provider>
    </WarehouseContext.Provider>
  );
};

/**
 * Custom hooks
 */

export function useWarehouseContext() {
  return useContext(WarehouseContext);
}

export function useWarehouseDispatch() {
  return useContext(WarehouseDispatchContext);
}

/**
 * Action definition
 */

export enum WarehouseActionType {
  SEARCH,
  CONFIGURATION_LOADED,
  SELECT_LOCATION,
  PRODUCT_FILTER,
  ADDITIONAL_PRODUCT_FILTER,
  SELECT_BATCH,
  SELECT_COMMODITY_ENTRY,
  SELECT_BATCH_ENTRY,
  SELECT_DELIVERY_ANNOUNCEMENT_ENTRY,
  SELECT_ORDER_ENTRY,
  SELECT_DESTINATION_ENTRY,
  SELECT_DELIVERY_ENTRY,
  SELECT_RESERVATION_ENTRY,
  SELECT_TAB,
  TRIGGER_ACTION
}

type WarehouseAction =
  | {
      type: WarehouseActionType.CONFIGURATION_LOADED;
      payload: { configuration: WarehouseConfiguration };
    }
  | { type: WarehouseActionType.SEARCH; payload: { query: string } }
  | { type: WarehouseActionType.SELECT_LOCATION; payload: { location: WarehouseLocation | null } }
  | { type: WarehouseActionType.PRODUCT_FILTER; payload: { productType: string } }
  | {
      type: WarehouseActionType.ADDITIONAL_PRODUCT_FILTER;
      payload: Partial<AdditionalProductFilter>;
    }
  | {
      type: WarehouseActionType.SELECT_BATCH;
      payload: { batch: Batch | null };
    }
  | {
      type: WarehouseActionType.SELECT_COMMODITY_ENTRY;
      payload: SelectedCommodityEntry;
    }
  | {
      type: WarehouseActionType.SELECT_BATCH_ENTRY;
      payload: SelectedBatchEntry;
    }
  | { type: WarehouseActionType.SELECT_DELIVERY_ANNOUNCEMENT_ENTRY; payload: SelectedDeliveryAnnouncementEntry }
  | { type: WarehouseActionType.SELECT_ORDER_ENTRY; payload: SelectedOrderEntry }
  | { type: WarehouseActionType.SELECT_DESTINATION_ENTRY; payload: SelectedDestinationEntry }
  | { type: WarehouseActionType.SELECT_DELIVERY_ENTRY; payload: SelectedDeliveryEntry }
  | { type: WarehouseActionType.SELECT_RESERVATION_ENTRY; payload: SelectedReservationEntry }
  | {
      type: WarehouseActionType.SELECT_TAB;
      payload: SelectTabPayload;
    }
  | { type: WarehouseActionType.TRIGGER_ACTION; payload: ActionTrigger };

/**
 * Reducer
 */

function warehouseReducer(state: WarehouseContext, action: WarehouseAction): WarehouseContext {
  switch (action.type) {
    case WarehouseActionType.SEARCH:
      return { ...state, selectedEntries: [], ...action.payload };
    case WarehouseActionType.CONFIGURATION_LOADED:
      return { ...state, configuration: action.payload.configuration };
    case WarehouseActionType.SELECT_LOCATION:
      if (!_.isEqual(state.selectedLocation, action.payload.location))
        return {
          ...state,
          selectedEntries: [],
          selectedLocation: action.payload.location
        };
      return state;
    case WarehouseActionType.PRODUCT_FILTER:
      return { ...state, selectedEntries: [], productFilter: action.payload.productType };
    case WarehouseActionType.ADDITIONAL_PRODUCT_FILTER:
      return {
        ...state,
        selectedEntries: [],
        additionalProductFilter: { ...state.additionalProductFilter, ...action.payload }
      };
    case WarehouseActionType.SELECT_BATCH:
      return { ...state, informationPanelBatch: action.payload.batch };
    case WarehouseActionType.SELECT_BATCH_ENTRY:
      const updatedBatchEntries = updateSelectedBatchEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedBatchEntries };
    case WarehouseActionType.SELECT_COMMODITY_ENTRY:
      const updatedCommodityEntries = updateSelectedCommodityEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedCommodityEntries };
    case WarehouseActionType.SELECT_DELIVERY_ANNOUNCEMENT_ENTRY:
      const updatedDeliveryAnnouncementEntries = updateSelectedDeliveryAnnouncements(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedDeliveryAnnouncementEntries };
    case WarehouseActionType.SELECT_ORDER_ENTRY:
      const updatedOrderEntries = updateSelectedOrderEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedOrderEntries };
    case WarehouseActionType.SELECT_DESTINATION_ENTRY:
      const updatedDestinationEntries = updateSelectedDestinationEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedDestinationEntries };
    case WarehouseActionType.SELECT_DELIVERY_ENTRY:
      const updatedDeliveryEntries = updateSelectedDeliveryEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedDeliveryEntries };
    case WarehouseActionType.SELECT_RESERVATION_ENTRY:
      const updatedReservationEntries = updateSelectedReservationEntries(state.selectedEntries, action);
      return { ...state, selectedEntries: updatedReservationEntries };
    case WarehouseActionType.SELECT_TAB:
      return {
        ...state,
        selectedEntries: getUpdatedSelectedEntries(state.selectedEntries, action.payload),
        activeTab: action.payload.tab,
        warehouseListingTabDocuments: [...action.payload.documents]
      };
    case WarehouseActionType.TRIGGER_ACTION:
      return { ...state, actionTrigger: action.payload };
    default:
      return state;
  }
}
