import { NumValue, WeightUnit } from "../model/common.types";
import { isPartOfEnum } from "./baseUtils";
import calculationUtils from "./calculationUtils";
import { DEFAULTWEIGHTUNIT } from "./warehouseUtils";

/**
 * Converts the NumValue from its current unit to the given unit
 * @param from the NumValue which should be converted
 * @param to the unit to which the conversion should be done
 * @returns {NumValue} the converted amount or, if the conversion failed because of an unknown unit or the unit was no weight unit, the original NumValue
 */
export function convertNumValueWeight(from: NumValue, to: string): NumValue {
  if (!isPartOfEnum(to, WeightUnit)) return from;
  const fromUnit = from.unit === WeightUnit.ug ? "ug" : from.unit; // make sure the display value for ug ("\u00b5g") is not used for conversion
  const convertedAmount = calculationUtils.convertAmount(from.value.toString(), fromUnit, to);
  if (+convertedAmount === from.value) return from;
  return {
    value: +convertedAmount,
    unit: to
  };
}

/**
 * Add the given numValues
 * @param unit optional, the unit the result should be in
 * @param numValues the numValues to add (one or multiple)
 * @returns {NumValue | undefined} the result of the added numValue in the given unit or the unit of the first numValue, if no unit was given; undefined if unit is no weight unit
 */
export function addNumValueWeight(unit?: string, ...numValues: Array<NumValue>): NumValue | undefined {
  if (numValues.length === 0) return { value: 0, unit: unit ?? DEFAULTWEIGHTUNIT };
  const conversionUnit = unit ?? numValues[0].unit ?? DEFAULTWEIGHTUNIT;
  if (!isPartOfEnum(conversionUnit, WeightUnit)) return undefined;
  return numValues.reduce(
    (acc, numValue) => {
      const convertedValue = convertNumValueWeight(numValue, conversionUnit);
      return {
        value: acc.value + convertedValue.value,
        unit: conversionUnit
      };
    },
    { value: 0, unit: conversionUnit }
  );
}

/**
 * Subtract the given numValues
 * @param baseValue the value from which all other values should be substracted
 * @param unit optional, the unit the result should be in
 * @param numValues the numValues to subtract (one or multiple)
 * @returns {NumValue | undefined} the result of the added numValue in the given unit or the unit of the first numValue, if no unit was given; undefined if unit is no weight unit
 */
export function subtractNumValueWeight(
  baseValue: NumValue,
  unit?: string,
  ...numValues: Array<NumValue>
): NumValue | undefined {
  if (numValues.length === 0) return baseValue;
  const conversionUnit = unit ?? baseValue.unit ?? DEFAULTWEIGHTUNIT;
  if (!isPartOfEnum(conversionUnit, WeightUnit)) return undefined;
  const convertedBase =
    conversionUnit !== baseValue.unit ? convertNumValueWeight(baseValue, conversionUnit) : baseValue;
  return numValues.reduce((acc, numValue) => {
    const convertedValue = convertNumValueWeight(numValue, conversionUnit);
    return {
      value: acc.value - convertedValue.value,
      unit: conversionUnit
    };
  }, convertedBase);
}
