import { BSON } from "realm-web";
import { WarehouseActionNumber } from "../../utils/warehouseActionUtils";
import { LanguageObject, NumValue } from "../common.types";
import { CompositionsDocument } from "../compositions.types";
import { Dimensions } from "../configuration/warehouseConfiguration.types";
import { Batch, BatchLocation } from "./batch.types";

export enum ContentType {
  COMMODITY = "commodity",
  PACKAGING = "packaging",
  CAPSULE = "capsule",
  FINISHEDPRODUCT = "finishedProduct"
}

export enum LocationType {
  STORAGESPACE = "storageSpace",
  PHYSICAL_WAREHOUSE = "physicalWarehouse"
}

export enum BatchContentSpecificType {
  CAPSULES = "capsules",
  SOFTGELS = "softgels",
  BOTTLES = "bottles",
  BAGS = "bags",
  BLISTERS = "blisters",
  LIDS = "lids",
  PIPETTES = "pipettes",
  SPRAY_PUMPS = "sprayPumps",
  LABELS = "labels",
  BOXES = "boxes",
  SLEEVES = "sleeves",
  OTHER_PACKAGING = "otherPackaging",
  FINISHED_PRODUCTS = "finishedProduct"
}

export type PUStorageSpaceAssignmentRec = { [pUId: string]: Array<PUStorageSpaceAssignment> };
export type StorageSpaceOccupationsRec = { [warehouseOrStorageSpaceId: string]: StorageSpaceOccupation };

// Inspired from https://stackoverflow.com/questions/47914536/use-partial-in-nested-property-with-typescript and https://norday.tech/posts/2021/typescript-partial/
export type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : T[K] extends Date | BSON.ObjectId
    ? T[K]
    : T[K] extends object
    ? NestedPartial<T[K]>
    : T[K] extends object | null
    ? NestedPartial<T[K]> | null
    : T[K] extends object | null | undefined
    ? NestedPartial<T[K]> | null | undefined
    : T[K];
};

export interface Content {
  type: ContentType; // general type
  details: ContentDetails;
}

export interface CommodityCategoriesSnapshot {
  _id: BSON.ObjectId;
  name: LanguageObject;
}

export interface CommoditySpecificTypeObject {
  composition: CompositionsDocument;
  category: CommodityCategoriesSnapshot;
}

// 'Snapshot' of materials, e.g. commodity, packaging, capsules, finished products, ...
export interface ContentDetails {
  _id: BSON.ObjectId; // commodity/packaging/capsules/FP ... id, reference
  specificType: BatchContentSpecificType | CommoditySpecificTypeObject; // e.g. softgel, powder, bottle, ..
  organic?: boolean;
  articleNo?: string; // currently only for commodity, but could be useful for everything tbh
  title: LanguageObject;
  subtitle: LanguageObject;
}

export interface StorageSpaceBatchInfo extends Pick<Batch, "_id" | "lot"> {
  contentSnapshot: Pick<ContentDetails, "_id" | "title" | "subtitle">;
}

export interface PUStorageSpaceAssignment {
  quantity: number;
  location: string | undefined;
  locationType: LocationType;
}

export interface LocationStorageSpaceAssignment {
  batchLocation: BatchLocation;
  pUAssignment: PUStorageSpaceAssignmentRec;
}

export interface BatchStorageSpaceAssignment {
  batchInfo: StorageSpaceBatchInfo;
  locationAssignments: Array<LocationStorageSpaceAssignment>;
}

export interface StorageSpaceOccupation {
  locationSnapshot: {
    locationName: string; // entrance or storageSpaceNo
    warehouseAreaId: string; // physical warehouse id, reference
    storageSpaceId?: string; // storage space id, reference
    dimensions?: Dimensions;
    maxWeight?: NumValue;
  };
  batchList: Set<string>;
  contentList: Set<string>;
  totalAmount: NumValue;
}

export interface CurrencyValue {
  value: number;
  currency: string;
}

export interface OrderSnapshot {
  _id: BSON.ObjectId; // order id, reference
  identifier: number | string;
}

export interface ExtendedOrderSnapshot extends OrderSnapshot {
  title: string;
  subtitle: string;
}

export interface PersonSnapshot {
  _id: BSON.ObjectId; // person id, reference
  prename: string;
  surname: string;
}

export interface BaseActionModalProps {
  show: boolean;
  actionTrigger?: ActionTrigger;
  onHide: () => void;
}

export interface ActionTrigger {
  actionNumber: WarehouseActionNumber | null;
  batchIds?: Array<string>;
  locationIds?: Array<string>;
  reservationId?: string;
  materialId?: string;
  orderId?: string;
  deliveryId?: string;
  destinationId?: string;
}
