import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { BSON } from "realm-web";
import i18n from "../../../translations/i18n";
import { useWarehouseContext } from "../../../context/warehouseContext";
import { resolveTranslation } from "../../../utils/translationUtils";
import baseUtils, { encodeString, formatNumValue } from "../../../utils/baseUtils";
import { useDataContext } from "../../../context/dataContext";
import {
  getBatchCommentDocument,
  getBatchFileEntry,
  getBatchStatusIndicationText,
  getBatchTimelineEntry
} from "../../../utils/batchUtils";
import { BatchComment, BatchFile, BatchTimelineSubType, BatchTimelineType } from "../../../model/warehouse/batch.types";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import dbService, { BATCH, UpdateAction } from "../../../services/dbService";
import toastUtils from "../../../utils/toastUtils";
import dateUtils from "../../../utils/dateUtils";
import { getBatchPackagingUnitDescription } from "../../../utils/warehouseUtils";
import { FileType, FileTypes, WarehouseTypes } from "../../../model/configuration/warehouseConfiguration.types";
import Tooltip from "../../common/Tooltip";
import DragAndDrop from "../../common/DragAndDrop";
import fileUtils from "../../../utils/fileUtils";
import { barcodePartitionalCalls, getBarcodeSVG, MAX_PARTITION } from "../../../utils/barcodeUtils";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import { createAllBarcodesPDF } from "../../../utils/pdf/warehouse/allBarcodesGeneration";
import config from "../../../config/config.json";

interface WarehouseInformationPanelProps {}

const WarehouseInformationPanel: React.FC<WarehouseInformationPanelProps> = React.memo(() => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h4 className="kt-portlet__head-title kt-font-bolder">{i18n.t("warehouse:information")}</h4>
        </div>
      </div>
      <div className="kt-portlet__body py-1">
        <WarehouseBatchInformation />
        <hr className="w-100 my-0" />
        <WarehouseInformationComments />
        <hr className="w-100 my-0" />
        <WarehouseFinancialAnalysis />
        <hr className="w-100 my-0" />
        <WarehouseInformationDocuments />
      </div>
    </div>
  );
});

const WarehouseBatchInformation: React.FC = () => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { commodities, reservation, universalBarcode, individualBarcode } = dataContext;
  const { informationPanelBatch } = warehouseContext;

  const [generating, setGenerating] = useState<boolean>(false);

  const batchBC = useMemo(() => {
    return universalBarcode.find(ub => ub.batch === informationPanelBatch?._id.toString());
  }, [universalBarcode, informationPanelBatch]);

  const individualBarcodes = useMemo(() => {
    return individualBarcode.filter(ib => ib.batch === informationPanelBatch?._id.toString());
  }, [individualBarcode, informationPanelBatch]);

  const batchStatusIndication: [color: string, statusKey: string] = useMemo(
    () => (!informationPanelBatch ? ["", ""] : getBatchStatusIndicationText(informationPanelBatch)),
    [reservation, informationPanelBatch]
  );

  const handleGenerateBarcode = async (universal?: boolean) => {
    setGenerating(true);
    try {
      if (informationPanelBatch) {
        const commodity = commodities.find(
          c => c._id.toString() === informationPanelBatch.content.details._id.toString()
        );
        if (commodity) {
          const commodityTitle = informationPanelBatch.content.details.title.en
            ? informationPanelBatch.content.details.title.en
            : commodity.title.en;
          if (universal) {
            const barcodeId = new BSON.ObjectId();
            const svg = getBarcodeSVG(
              barcodeId.toString(),
              `${commodityTitle.replaceAll(/\s/g, "").substring(0, 10)} - ${barcodeId.toString()}`
            );
            const res = await dbService.callFunction<boolean>(
              "generateBarcode",
              [informationPanelBatch._id, svg, barcodeId],
              true
            );
            if (res) toast.success(i18n.t("warehouse:barcodeGenerationSuccess"));
            else toast.error(i18n.t("warehouse:barcodeGenerationFailure"));
          } else {
            //individual barcodes
            const barcodesArray: Array<{ barcodeId: BSON.ObjectId; svg: string; packageNo: number }> = [];
            let packageCount = 0;
            for (let i = 0; i < informationPanelBatch.locations.length; i++) {
              for (let j = 0; j < informationPanelBatch.locations[i].packagingUnits.length; j++) {
                const packageQuantity = informationPanelBatch.locations[i].packagingUnits[j].quantity;
                if (packageQuantity) {
                  for (let k = 0; k < packageQuantity; k++) {
                    packageCount += 1;
                    const barcodeId = new BSON.ObjectId();
                    const toEncode = barcodeId.toString() + "_" + packageCount.toString();
                    barcodesArray.push({ barcodeId, svg: toEncode, packageNo: packageCount });
                  }
                }
              }
            }
            let res;
            if (barcodesArray.length > MAX_PARTITION) {
              await barcodePartitionalCalls(informationPanelBatch._id, barcodesArray, MAX_PARTITION, commodityTitle);
            } else {
              for (let i = 0; i < barcodesArray.length; i++) {
                const svg = getBarcodeSVG(
                  barcodesArray[i].svg,
                  `${commodityTitle.replaceAll(/\s/g, "").substring(0, 10)} - ${barcodesArray[i].svg}`
                );
                // exchange string to be encoded with actual svg
                barcodesArray[i].svg = svg;
              }
              res = await dbService.callFunction<boolean>(
                "generateBarcode",
                [informationPanelBatch._id, undefined, undefined, barcodesArray],
                true
              );
              if (res) toast.success(i18n.t("warehouse:pUBarcodeGenerationSuccess"));
              else toast.error(i18n.t("warehouse:pUBarcodeGenerationFailure"));
            }
          }
        }
      } else {
        toast.error(i18n.t("warehouse:batchNotFoundError"));
      }
    } catch (e) {
      toast.error(i18n.t("warehouse:pUBarcodeGenerationFailure", e));
    } finally {
      setGenerating(false);
    }
  };

  const handleBarcodePDF = async () => {
    setGenerating(true);
    try {
      if (informationPanelBatch) {
        const barcodePDF = JSON.stringify({
          html: createAllBarcodesPDF(informationPanelBatch, individualBarcodes),
          fileName:
            "Batch-" +
            encodeString(informationPanelBatch.lot) +
            "_" +
            encodeString(resolveTranslation(informationPanelBatch.content.details.title).substring(0, 15)) +
            "_" +
            encodeString(resolveTranslation(informationPanelBatch.content.details.subtitle).substring(0, 10)) +
            ".pdf"
        });
        const path = await pdfUtils.uploadAndReturnPath(barcodePDF);
        window.open(config.mediahubBase + path, "_blank");
      }
    } catch (e) {
      toast.error(i18n.t("warehouse:pdfGenerationFailure", e));
    } finally {
      setGenerating(false);
    }
  };

  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionBatchInfo">
      <div className="card mb-0">
        <div className="card-header" id="headingBatchInfo">
          <div className="card-title" data-toggle="collapse" data-target="#collapseBatchInfo">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:batchInformation")}
            </span>
          </div>
        </div>
        <div id="collapseBatchInfo" className="collapse show" data-parent="#accordionBatchInfo">
          <div className="card-body mb-3">
            {informationPanelBatch ? (
              <>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productType")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {i18n.t(`warehouse:${informationPanelBatch.content.type}`)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:productTitle")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {resolveTranslation(informationPanelBatch.content.details.title)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">
                    {i18n.t("warehouse:productInformation")}:
                  </div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {resolveTranslation(informationPanelBatch.content.details.subtitle)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:supplier")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {informationPanelBatch.sender.name}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:lot")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black">{informationPanelBatch.lot}</div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:stored")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {baseUtils.formatDate(informationPanelBatch.stocked)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:bestBefore")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {baseUtils.formatDate(informationPanelBatch.expiry)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:amount")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {formatNumValue(informationPanelBatch.totalAmount, 2)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:packaging")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {getBatchPackagingUnitDescription(informationPanelBatch.locations.flatMap(l => l.packagingUnits))}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:status")}:</div>
                  <div className={"col-8 col-form-label p-m-0 kt-font-bold " + batchStatusIndication[0]}>
                    {i18n.t(`warehouse:${batchStatusIndication[1]}`)}
                  </div>
                </div>
                <div className="form-group form-group-xs d-flex info-row">
                  <div className="col-4 col-form-label p-m-0 text-black ">{i18n.t("warehouse:barcode")}:</div>
                  <div className="col-8 col-form-label p-m-0 kt-font-bold text-black ">
                    {batchBC ? (
                      <img
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(batchBC.barcode)}`}
                        alt="Universal Barcode"
                      />
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light btn-sm text-black m-1"
                        onClick={() => handleGenerateBarcode(true)}
                      >
                        {i18n.t("warehouse:barcodeGeneration")}
                      </button>
                    )}
                  </div>
                </div>
                <div className="accordion accordion-light accordion-toggle-arrow" id="accordionIndividualBarcodeInfo">
                  <div className="card mb-0">
                    <div className="card-header" id="headingIndividualBarcodeInfo">
                      <div
                        className="card-title collapsed"
                        data-toggle="collapse"
                        data-target="#collapseIndividualBarcodeInfo"
                      >
                        <span className="navi-text font-weight-medium font-size-lg text-black">
                          {i18n.t("warehouse:individualBarcodes")}
                        </span>
                      </div>
                    </div>
                    <div
                      id="collapseIndividualBarcodeInfo"
                      className="collapse"
                      data-parent="#accordionIndividualBarcodeInfo"
                    >
                      <div className="card-body">
                        {individualBarcodes && individualBarcodes.length > 0 ? (
                          <div>
                            <ErrorOverlayButton
                              buttonText={
                                generating ? `${i18n.t("common:generating")}...` : i18n.t("warehouse:printAllBarcodes")
                              }
                              errors={
                                !(individualBarcodes && individualBarcodes.length > 0)
                                  ? [i18n.t("warehouse:noBarcodes")]
                                  : []
                              }
                              className="btn btn-light btn-sm text-black m-1"
                              saving={generating}
                              onClick={handleBarcodePDF}
                            />
                            {individualBarcodes.map(ib => {
                              return (
                                <div key={ib._id.toString()} className="mb-1">
                                  <img
                                    src={`data:image/svg+xml;utf8,${encodeURIComponent(ib.barcode)}`}
                                    alt="Individual Barcode"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <ErrorOverlayButton
                            buttonText={
                              generating ? `${i18n.t("common:generating")}...` : i18n.t("warehouse:pUBarcodeGeneration")
                            }
                            errors={
                              informationPanelBatch.locations.some(
                                l => l.location.warehouseArea.type === WarehouseTypes.REMOTE
                              ) && !individualBarcodes.some(ib => ib.batch === informationPanelBatch._id.toString())
                                ? [i18n.t("warehouse:generateIndividualBarcodesNotPossible")]
                                : []
                            }
                            saving={generating}
                            className="btn btn-light btn-sm text-black m-1"
                            onClick={() => handleGenerateBarcode()}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForInformation")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WarehouseFinancialAnalysis: React.FC = () => {
  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionFinancialAnalysis">
      <div className="card mb-0">
        <div className="card-header" id="headingFinancialAnalysis">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseFinancialAnalysis">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:financialAnalysis")}
            </span>
          </div>
        </div>
        <div id="collapseFinancialAnalysis" className="collapse " data-parent="#accordionFinancialAnalysis">
          <div className="card-body">
            <span className="text-black d-block mb-3">{i18n.t("warehouse:comingSoon")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const WarehouseInformationDocuments: React.FC = () => {
  const warehouseContext = useWarehouseContext();
  const { informationPanelBatch, configuration } = warehouseContext;

  const fileTypes = useMemo(
    () => (configuration ? configuration.values.fileTypes.filter(fT => fT.subtype === FileTypes.BATCH) : []),
    [configuration?.values.fileTypes]
  );

  if (!configuration) return null;
  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionDocuments">
      <div className="card mb-0">
        <div className="card-header" id="headingDocuments">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseDocuments">
            <span className="navi-text font-weight-medium font-size-lg text-black">
              {i18n.t("warehouse:documents")}
            </span>
          </div>
        </div>
        <div id="collapseDocuments" className="collapse " data-parent="#accordionDocuments">
          <div className="card-body">
            {informationPanelBatch ? (
              <div className="kt-widget4">
                {informationPanelBatch.files.map(f => (
                  <WarehouseInformationDocument key={f._id.toString()} file={f} />
                ))}
                <div className="tab-pane mt-3" role="tabpanel">
                  <div className="kt-section">
                    <div className="kt-section__content " style={{ borderLeft: "none" }}>
                      {fileTypes.map(fT => (
                        <WarehouseInformationUploadDocumentModal key={fT._id.toString()} fileType={fT} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForDocuments")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface WarehouseInformationDocumentProps {
  file: BatchFile;
}

const WarehouseInformationDocument: React.FC<WarehouseInformationDocumentProps> = ({ file }) => {
  const [saving, setSaving] = useState(false);
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { informationPanelBatch } = warehouseContext;

  const handleRemoveFile = useCallback(async () => {
    if (!informationPanelBatch) return;
    setSaving(true);
    try {
      const action: UpdateAction = {
        collection: BATCH,
        filter: { _id: informationPanelBatch._id },
        pull: { files: file }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully removed file", "File could not be removed", () => {
        dataContext.updateDocumentInContext(BATCH, informationPanelBatch._id);
      });
    } finally {
      setSaving(false);
    }
  }, [informationPanelBatch, file]);

  return (
    <div className="kt-widget4__item">
      <div className="kt-widget4__pic kt-widget4__pic--icon">
        <img src="/media/icons/pdf_icon.png" alt="" />
      </div>
      <div className="kt-widget4__info">
        <div>
          <a href={file.path} target="_blank" rel="noopener noreferrer" className="kt-widget4__username mr-2">
            {file.title}
          </a>
        </div>
        <p className="kt-widget4__text">{dateUtils.getTimeAgo(file.date)}</p>
      </div>
      <ErrorOverlayButton
        buttonText={<i className="flaticon2-cross px-1 line-height-sm" />}
        className={"btn btn-danger btn-sm p-2"}
        errors={[]}
        onClick={handleRemoveFile}
        saving={saving}
      />
    </div>
  );
};

interface WarehouseInformationUploadDocumentModalProps {
  fileType: FileType;
}

const WarehouseInformationUploadDocumentModal: React.FC<WarehouseInformationUploadDocumentModalProps> = ({
  fileType
}) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const { updateDocumentInContext } = dataContext;
  const { informationPanelBatch } = warehouseContext;

  const [show, setShow] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (!informationPanelBatch) errors.push(i18n.t("warehouse:errorNoBatchSelected"));
    if (!file) errors.push(i18n.t("warehouse:errorNoFileSelected"));
    return errors;
  }, [informationPanelBatch, file]);

  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  const handleSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement> | { target: HTMLInputElement }) => {
    const file = e.target.files && e.target.files[0];
    const maxFileSize = 50 * 1000 * 1000;
    if (file && file.size <= maxFileSize) {
      setFile(file);
    } else if (file && file.size > maxFileSize) toast.error(i18n.t("warehouse:fileTooBig"));
  }, []);

  const handleSave = useCallback(async () => {
    if (!file || !informationPanelBatch) return;
    setSaving(true);
    try {
      const name = file.name.split(".")[0];
      const fileExtension = fileUtils.getFileExtension(file.name);
      const fileName = baseUtils.encodeString(name) + "." + fileExtension;
      const path = fileUtils.uploadFile(file, fileName);
      if (path) {
        const batchFile = getBatchFileEntry(path, file, fileType);
        const timelineEntry = getBatchTimelineEntry(
          BatchTimelineType.BATCHFILEUPLOADED,
          BatchTimelineSubType.DOCUMENT,
          { file: batchFile },
          {
            pre: { files: informationPanelBatch.files },
            post: { files: informationPanelBatch.files.concat(batchFile) }
          }
        );
        const action: UpdateAction = {
          collection: BATCH,
          filter: { _id: informationPanelBatch._id },
          push: { files: batchFile, timeline: timelineEntry }
        };
        const result = await dbService.transaction([action]);
        if (result) {
          toast.success(i18n.t("warehouse:uploadFileTypeSuccess", { fileType: resolveTranslation(fileType.type) }));
          updateDocumentInContext(BATCH, informationPanelBatch._id);
          handleClose();
        } else toast.error(i18n.t("common:errorFileUpload"));
      } else toast.error(i18n.t("common:errorFileUpload"));
    } finally {
      setSaving(false);
    }
  }, [informationPanelBatch, file, fileType]);

  return (
    <>
      <Tooltip tooltipText={resolveTranslation(fileType.type)}>
        <button type="button" className="btn btn-light btn-sm text-black m-1" onClick={handleShow}>
          {fileType.shortName}
        </button>
      </Tooltip>
      <Modal show={show} onHide={handleClose} centered dialogClassName={"extendedModalDialog"} name={"uploadFileModal"}>
        <Modal.Header closeButton>
          <Modal.Title as={"h5"}>
            <b>{i18n.t("warehouse:uploadFileType", { fileType: resolveTranslation(fileType.type) })}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DragAndDrop file={file} onSelectFile={handleSelectFile} />
        </Modal.Body>
        <Modal.Footer>
          <ErrorOverlayButton
            saving={saving}
            buttonText={i18n.t("common:close")}
            className={"btn btn-secondary"}
            onClick={handleClose}
          />
          <ErrorOverlayButton
            errors={errors}
            saving={saving}
            className={"btn btn-success"}
            buttonText={i18n.t("common:upload")}
            onClick={handleSave}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

const WarehouseInformationComments: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const [newComment, setNewComment] = useState<string>("");

  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { informationPanelBatch } = warehouseContext;

  const handleChangeComment = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setNewComment(e.target.value),
    []
  );

  const handleSaveComment = useCallback(async () => {
    if (!informationPanelBatch || !newComment.trim()) return;
    setSaving(true);

    try {
      const action: UpdateAction = {
        collection: BATCH,
        filter: { _id: informationPanelBatch._id },
        push: { comments: getBatchCommentDocument(newComment) }
      };
      const res = await dbService.transaction([action]);
      toastUtils.databaseOperationToast(res, "Successfully added comment", "Comment could not be updated", () => {
        setNewComment("");
        dataContext.updateDocumentInContext(BATCH, informationPanelBatch._id);
      });
    } finally {
      setSaving(false);
    }
  }, [informationPanelBatch, newComment]);

  return (
    <div className="accordion accordion-light accordion-toggle-arrow" id="accordionComments">
      <div className="card mb-0">
        <div className="card-header" id="headingComments">
          <div className="card-title" data-toggle="collapse" data-target="#collapseComments">
            <span className="navi-text font-weight-medium font-size-lg text-black">{i18n.t("warehouse:comments")}</span>
            {informationPanelBatch && (
              <span
                className={
                  "label label-sm font-weight-bolder label-rounded ml-2 " +
                  (informationPanelBatch.comments.length > 0 ? "label-danger" : "")
                }
              >
                {informationPanelBatch.comments.length}
              </span>
            )}
          </div>
        </div>
        <div id="collapseComments" className="collapse show" data-parent="#accordionComments">
          <div className="card-body">
            {informationPanelBatch ? (
              <>
                {informationPanelBatch.comments.length > 0 && (
                  <div className="timeline timeline-justified timeline-4">
                    <div className="timeline-bar" />
                    <div className="timeline-items">
                      {informationPanelBatch.comments.map(c => (
                        <WarehouseInformationComment key={c._id.toString()} comment={c} />
                      ))}
                    </div>
                  </div>
                )}
                <div className="kt-portlet__body kt-portlet__body--fit-top mt-3">
                  <div className="form-group form-group-last mb-3">
                    <textarea
                      className="form-control"
                      id="newCommentTextArea"
                      value={newComment}
                      rows={5}
                      onChange={handleChangeComment}
                      placeholder={i18n.t("warehouse:addCommentPlaceholder")}
                    />
                  </div>
                  <div className="kt-section kt-section--last">
                    <ErrorOverlayButton
                      buttonText={i18n.t("warehouse:saveComment")}
                      className={"btn btn-label-brand btn-sm btn-bold"}
                      errors={newComment.trim() ? [] : [i18n.t("warehouse:commentEmpty")]}
                      onClick={handleSaveComment}
                      saving={saving}
                    />
                  </div>
                </div>
              </>
            ) : (
              <span className="text-black d-block mb-3">{i18n.t("warehouse:selectBatchForComments")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

interface WarehouseInformationCommentProps {
  comment: BatchComment;
}

const WarehouseInformationComment: React.FC<WarehouseInformationCommentProps> = ({ comment }) => {
  return (
    <div className="timeline-item">
      <div className="timeline-badge">
        <div className="bg-secondary"></div>
      </div>
      <div className="timeline-label pl-0">
        <span className="text-black ">
          {`${comment.person.prename} ${comment.person.surname}`}, {baseUtils.formatDate(comment.date)}
        </span>
      </div>
      <div className="timeline-content text-black">{comment.text}</div>
    </div>
  );
};

export default WarehouseInformationPanel;
