import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import { useWarehouseContext, useWarehouseDispatch, WarehouseActionType } from "../../../context/warehouseContext";
import { useDataContext } from "../../../context/dataContext";
import { ActionTrigger, BaseActionModalProps, ExtendedOrderSnapshot } from "../../../model/warehouse/common.types";
import { ReservationState, ReservedMaterial } from "../../../model/warehouse/reservation.types";
import baseUtils, { formatNumValue } from "../../../utils/baseUtils";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import BaseListing from "../../listings/BaseListing";
import { paginate } from "../../common/Pagination";
import dbService, { RESERVATION } from "../../../services/dbService";
import { isSelectedReservationEntries, WarehouseActionNumber } from "../../../utils/warehouseActionUtils";
import { CommoditiesDocument } from "../../../model/commodities.types";
import SplashScreen from "../../common/SplashScreen";

enum CancelMode {
  CANCEL,
  REASSIGN
}

interface ExtendedReservedMaterial extends ReservedMaterial {
  reservationId: string;
  identifier: string;
  order: ExtendedOrderSnapshot;
}

interface SelectedReservedMaterial {
  reservation: string;
  material: string;
  order: string;
}

interface CancelReservationModalState {
  saving: boolean;
  material: CommoditiesDocument | undefined;
  relevantReservationMaterials: Array<ExtendedReservedMaterial>;
  selectedReservationMaterials: Array<SelectedReservedMaterial>;
}

const getDefaultState = (): CancelReservationModalState => {
  return {
    saving: false,
    material: undefined,
    relevantReservationMaterials: [],
    selectedReservationMaterials: []
  };
};

const CancelReservationModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const dataContext = useDataContext();
  const warehouseContext = useWarehouseContext();
  const dispatch = useWarehouseDispatch();
  const { commodities, reservation, updateDocumentInContext } = dataContext;
  const { selectedEntries } = warehouseContext;

  const [state, setState] = useState(getDefaultState());
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    let relevantReservationMaterials: Array<ExtendedReservedMaterial> = [];
    let selectedReservationMaterials: Array<SelectedReservedMaterial> = [];
    if (
      !show ||
      !(actionTrigger?.materialId || (isSelectedReservationEntries(selectedEntries) && selectedEntries.length > 0))
    )
      return;
    const material = commodities.find(
      c =>
        c._id.toString() ===
        (actionTrigger
          ? actionTrigger.materialId
          : isSelectedReservationEntries(selectedEntries) && selectedEntries.length > 0
          ? selectedEntries[0].materialId
          : "")
    );

    if (material) {
      const locationIds: Array<[rId: string | undefined, lId: string | undefined]> =
        actionTrigger && actionTrigger.locationIds && actionTrigger.locationIds.length > 0
          ? [[actionTrigger.reservationId, actionTrigger.locationIds[0]]]
          : isSelectedReservationEntries(selectedEntries) && selectedEntries.length > 0
          ? selectedEntries.map(e => [e.reservationId, e.locationId])
          : [];
      reservation.forEach(r => {
        if (r.state !== ReservationState.OPEN) return;
        const relevantIds = locationIds.filter(([rId]) => rId === r._id.toString());
        if (relevantIds.length === 0) return;
        r.materials.forEach(m => {
          if (
            !relevantIds.some(
              ([, lId]) =>
                m.material.details._id.toString() === material._id.toString() &&
                (!lId || m.warehouseSnapshot._id.toString() === lId)
            )
          )
            return;
          relevantReservationMaterials.push({
            ...m,
            order: r.order,
            reservationId: r._id.toString(),
            identifier: r.identifier
          });
          selectedReservationMaterials.push({
            reservation: r._id.toString(),
            material: m._id.toString(),
            order: r.order._id.toString()
          });
        });
      });
    }

    setState(prevState => {
      return {
        ...prevState,
        material,
        relevantReservationMaterials,
        selectedReservationMaterials
      };
    });
  }, [show, commodities, reservation, actionTrigger, selectedEntries]);

  const selectedOrders = useMemo(
    () => Array.from(new Set(state.selectedReservationMaterials.map(m => m.order))),
    [state.selectedReservationMaterials]
  );

  const errors = useMemo(() => {
    const errors = new Set<string>();
    if (state.selectedReservationMaterials.length === 0) {
      errors.add(i18n.t("warehouse:reservationNoOrderSelectedError"));
    }
    return Array.from(errors);
  }, [state.selectedReservationMaterials]);

  const handleHide = () => {
    if (state.saving) return;
    onHide();
  };

  const handleChangePage = useCallback(currentPage => setCurrentPage(currentPage), []);
  const handleChangePageSize = useCallback(pageSize => setPageSize(pageSize), []);

  const handleCheckReservation = useCallback(
    (rMat: ExtendedReservedMaterial) => {
      let selectedReservationMaterials = [...state.selectedReservationMaterials];
      const existingIndex = selectedReservationMaterials.findIndex(
        s => s.reservation === rMat.reservationId && s.material === rMat._id.toString()
      );

      if (existingIndex !== -1) selectedReservationMaterials.splice(existingIndex, 1);
      else
        selectedReservationMaterials.push({
          reservation: rMat.reservationId,
          material: rMat._id.toString(),
          order: rMat.order._id.toString()
        });

      setState(prevState => {
        return {
          ...prevState,
          selectedReservationMaterials
        };
      });
    },
    [state.selectedReservationMaterials]
  );

  const handleCancelReservation = useCallback(
    async (mode: CancelMode) => {
      const { selectedReservationMaterials, material } = state;
      if (selectedReservationMaterials.length === 0 || !material) return;
      setState(prevState => {
        return { ...prevState, saving: true };
      });
      try {
        const result = await dbService.callFunction<boolean>(
          "cancelReservations",
          [selectedReservationMaterials],
          true
        );
        if (result) {
          const uniqueReservations = Array.from(new Set(selectedReservationMaterials.map(m => m.reservation)));
          for (let i = 0; i < uniqueReservations.length; i++)
            await updateDocumentInContext(RESERVATION, uniqueReservations[i]);

          toast.success(i18n.t("warehouse:reservationCancellationSuccess"));
          onHide();
          if (mode === CancelMode.REASSIGN) {
            const payload: ActionTrigger = {
              actionNumber: WarehouseActionNumber.CREATE_RESERVATION,
              orderId: selectedOrders.length === 1 ? selectedOrders[0] : undefined,
              materialId: material._id.toString()
            };
            dispatch({
              type: WarehouseActionType.TRIGGER_ACTION,
              payload: payload
            });
          }
        } else {
          toast.error(i18n.t("warehouse:reservationCancellationFailure"));
        }
      } finally {
        setState(prevState => {
          return { ...prevState, saving: false };
        });
      }
    },
    [state.material, state.selectedReservationMaterials, selectedOrders]
  );

  const tableHeader = [{ title: "#", size: 1 }, { title: i18n.t("common:order") }, { title: i18n.t("common:amount") }];

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:deleteReservation")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <h4 className="mb-3 font-weight-bold text-black">{i18n.t("warehouse:cancelReservationConfirmation")}</h4>
          <div className="alert alert-warning" role="alert">
            <div className="alert-icon">
              <i className="flaticon-warning"></i>
            </div>
            <div className="alert-text">{i18n.t("warehouse:cancelReservationOrderWarning")}</div>
          </div>
          {state.saving ? (
            <SplashScreen additionalSVGStyle={{ height: "80px", width: "80px" }} />
          ) : state.material ? (
            <>
              <hr className="w-100" />
              <BaseListing
                headerDefinition={tableHeader}
                documents={state.relevantReservationMaterials}
                bodyContent={
                  <>
                    {paginate(state.relevantReservationMaterials, currentPage, pageSize).map(rMaterial => (
                      <CancelReservationRow
                        key={rMaterial._id.toString()}
                        reservationMaterial={rMaterial}
                        isSelected={state.selectedReservationMaterials.some(
                          s => s.reservation === rMaterial.reservationId && s.material === rMaterial._id.toString()
                        )}
                        onCheckReservation={handleCheckReservation}
                      />
                    ))}
                  </>
                }
                currentPage={currentPage}
                pageSize={pageSize}
                onPageChange={handleChangePage}
                onPageSizeChange={handleChangePageSize}
              />
            </>
          ) : (
            <div>{i18n.t("warehouse:noReservationForSelectionFound")}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          saving={state.saving}
          onClick={onHide}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:reassign")}
          className="btn btn-success"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.REASSIGN)}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:cancelReservation")}
          className="btn btn-danger"
          errors={errors}
          saving={state.saving}
          onClick={() => handleCancelReservation(CancelMode.CANCEL)}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface CancelReservationRowProps {
  reservationMaterial: ExtendedReservedMaterial;
  isSelected: boolean;
  onCheckReservation: (rMat: ExtendedReservedMaterial) => void;
}

const CancelReservationRow: React.FC<CancelReservationRowProps> = ({
  reservationMaterial,
  isSelected,
  onCheckReservation
}) => {
  return (
    <tr className={"kt-datatable__row d-table-row  "}>
      <td className="kt-datatable__cell d-table-cell ">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={() => onCheckReservation(reservationMaterial)}
              className="ml-0 kt-checkbox--solid"
            />
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {`AT-${reservationMaterial.order.identifier} ${baseUtils.truncateString(
              reservationMaterial.order.title,
              25
            )}`}
          </div>
        </div>
      </td>
      <td className="kt-datatable__cell d-table-cell">
        <div className="kt-user-card-v2">
          <div className="kt-user-card-v2__details  text-black kt-font-bold">
            {formatNumValue(reservationMaterial.reservedAmount)}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CancelReservationModal;
