import React, { useCallback, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import i18n from "../../../../translations/i18n";
import {
  StorageSpaceBatchInfo,
  StorageSpaceOccupation,
  StorageSpaceOccupationsRec
} from "../../../../model/warehouse/common.types";
import baseUtils, { formatNumValueLocale } from "../../../../utils/baseUtils";
import { getStorageSpaceOccupationColor } from "../../../../utils/warehouseStorageSpaceUtils";

const MAX_RECOMMENDATIONS = 10;

interface StorageSpaceRecommendationsProps {
  storageSpaceOccupations?: StorageSpaceOccupationsRec;
  storageSpaceSearch: string;
  batch: StorageSpaceBatchInfo;
  onSelectRecommendation: (storageSpaceId: string) => void;
}

const StorageSpaceRecommendations: React.FC<StorageSpaceRecommendationsProps> = ({
  storageSpaceOccupations,
  storageSpaceSearch,
  batch,
  onSelectRecommendation
}) => {
  const occupations = useMemo(() => {
    if (!storageSpaceOccupations) return undefined;
    // Exclude entrance from recommendations, it can still be selected in the typeahead
    const occupations = Object.values(storageSpaceOccupations).filter(o => o.locationSnapshot.storageSpaceId);
    const occupationsWithLabel = [];

    const occupationByAmount = storageSpaceSearch
      ? baseUtils.doFuseSearch(occupations, storageSpaceSearch, ["locationSnapshot.locationName"])
      : occupations;
    const filteredOccupationsByAmount = occupationByAmount
      .sort((a, b) => a.totalAmount.value - b.totalAmount.value)
      .slice(0, MAX_RECOMMENDATIONS);
    occupationsWithLabel.push({ label: i18n.t("warehouse:bestMatch"), value: filteredOccupationsByAmount });

    const occupationsByBatch = occupations.filter(o => o.batchList.has(batch._id.toString()));
    const filteredOccupationsByBatch = occupationsByBatch
      .sort((a, b) => a.totalAmount.value - b.totalAmount.value)
      .slice(0, MAX_RECOMMENDATIONS);
    occupationsWithLabel.push({ label: i18n.t("warehouse:sameBatch"), value: filteredOccupationsByBatch });

    const occupationsByCommodity = occupations.filter(o => o.contentList.has(batch.contentSnapshot._id.toString()));
    const filteredOccupationsByCommodity = occupationsByCommodity
      .sort((a, b) => a.totalAmount.value - b.totalAmount.value)
      .slice(0, MAX_RECOMMENDATIONS);
    occupationsWithLabel.push({ label: i18n.t("warehouse:sameCommodity"), value: filteredOccupationsByCommodity });

    return occupationsWithLabel;
  }, [storageSpaceOccupations, storageSpaceSearch, batch]);

  return (
    <div className="row">
      <div className="col-12">
        <span className=" font-size-lg text-black d-block mt-5" style={{ fontWeight: 500 }}>
          {i18n.t("warehouse:storageSpaceSuggestions")}
        </span>
        {occupations?.map((o, categoryIndex) => {
          return (
            <div className="form-group form-group-xs d-flex info-row my-3" key={categoryIndex}>
              <div className="col-4 text-black pl-0">{o.label}:</div>
              <div className="col-8 kt-font-bold text-black ">
                {o.value.map((stSp, badgeIndex) => (
                  <StorageSpaceBadge
                    key={categoryIndex + "_" + badgeIndex}
                    storageSpaceOccupation={stSp}
                    onSelectRecommendation={onSelectRecommendation}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface StorageSpaceBadgeProps {
  storageSpaceOccupation: StorageSpaceOccupation;
  onSelectRecommendation: (storageSpaceId: string) => void;
}

const StorageSpaceBadge: React.FC<StorageSpaceBadgeProps> = ({ storageSpaceOccupation, onSelectRecommendation }) => {
  const badgeColor = useMemo(() => {
    const color = getStorageSpaceOccupationColor(storageSpaceOccupation);
    if (color.length === 2) return color[1];
  }, [storageSpaceOccupation]);

  const handleSelectRecommendation = useCallback(() => {
    if (storageSpaceOccupation.locationSnapshot.storageSpaceId)
      onSelectRecommendation(storageSpaceOccupation.locationSnapshot.storageSpaceId);
  }, [storageSpaceOccupation, onSelectRecommendation]);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="layout-tooltip">
          <span>{i18n.t("warehouse:storageSpaceOccupation")} </span>
          <span className="font-weight-bold">{formatNumValueLocale(storageSpaceOccupation.totalAmount)}</span>
        </Tooltip>
      }
    >
      <span
        className="label label-light text-black label-inline font-weight-lighter mr-1 mb-1 p-2 pointer"
        onClick={handleSelectRecommendation}
      >
        <div className={`badge-dot ${badgeColor}`}></div>
        {storageSpaceOccupation.locationSnapshot.locationName}
      </span>
    </OverlayTrigger>
  );
};

export default StorageSpaceRecommendations;
