import _ from "lodash";
import { BSON } from "realm-web";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import i18n from "../../../translations/i18n";
import ErrorOverlayButton from "../../common/ErrorOverlayButton";
import { SelectedEntryType, useWarehouseContext } from "../../../context/warehouseContext";
import { ActionTrigger, BaseActionModalProps } from "../../../model/warehouse/common.types";
import {
  Delivery,
  DeliveryBatchSnapshot,
  DeliveryFile,
  OutgoingSnapshot,
  PositionEntry
} from "../../../model/warehouse/delivery.types";
import { Input } from "../../common/Input";
import DateInput from "../../common/DateInput";
import { isSelectedDestinationsEntries } from "../../../utils/warehouseActionUtils";
import { TabDocument } from "../../../model/warehouse/customTypes.types";
import { isDestinationsWithOutgoing } from "../../../utils/warehouseFilterUtils";
import { Outgoing } from "../../../model/warehouse/outgoing.types";
import { PackagingUnit } from "../../../model/warehouse/batch.types";
import { resolveTranslation } from "../../../utils/translationUtils";
import { getFormattedPackagingUnitAmount, getFormattedPackagingUnitTotalAmount } from "../../../utils/warehouseUtils";
import baseUtils, { getCountryNameForCode } from "../../../utils/baseUtils";
import dbService, { DELIVERY, OUTGOING, UpdateAction } from "../../../services/dbService";
import { useDataContext } from "../../../context/dataContext";
import { createDeliveryNote } from "../../../utils/pdf/warehouse/deliveryNoteGeneration";
import pdfUtils from "../../../utils/pdf/pdfUtils";
import config from "../../../config/config.json";
import userService from "../../../services/userService";

export interface DeliveryNoteSettings {
  recipientText: string;
  title: string;
  additionalInformation: string;
  eta: Date;
  positions: Array<PositionEntry>;
}

const getEmptyDeliveryNotePosition = (): PositionEntry => {
  return {
    _id: new BSON.ObjectId(),
    packagingUnits: "",
    title: "",
    description: "",
    batch: null,
    universalBarcode: null,
    isFreePosition: true
  };
};

const getEmptyDeliveryNoteSettings = (): DeliveryNoteSettings => {
  return {
    recipientText: "",
    title: "",
    eta: new Date(),
    positions: [getEmptyDeliveryNotePosition()],
    additionalInformation: ""
  };
};

const getRelevantOutgoingsWithPUs = (
  selectedEntries: Array<SelectedEntryType>,
  actionTrigger: ActionTrigger | undefined,
  documents: Array<TabDocument>,
  deliveries: Array<Delivery>
): [outgoings: Array<Outgoing | OutgoingSnapshot>, delivery?: Delivery] => {
  if (!!actionTrigger?.deliveryId) {
    // Edit of existing delivery note
    const delivery: Delivery = baseUtils.getDocFromCollection(deliveries, actionTrigger.deliveryId);
    if (!delivery) return [[]];
    return [delivery.deliveries, delivery];
  }
  if (
    !actionTrigger?.destinationId ||
    !isSelectedDestinationsEntries(selectedEntries) ||
    !isDestinationsWithOutgoing(documents)
  )
    return [[]];
  const selectedDestination = documents.find(d => d._id.toString() === actionTrigger.destinationId);
  if (!selectedDestination) return [[]];

  let relevantOutgoings = selectedDestination.outgoing.slice();
  // Check if a specific selection exists
  const filter = selectedEntries.filter(
    e => e && e.destinationId === actionTrigger.destinationId && e.batchId && e.packagingUnitId
  );
  if (filter.length > 0) {
    relevantOutgoings = relevantOutgoings.filter(o =>
      filter.some(
        f =>
          o.destination._id.toString() === f.destinationId &&
          o.batch._id.toString() === f.batchId &&
          o.movedPackagingUnits.some(
            p => p._id.toString() === f.packagingUnitId || (p.quantity === null && f.packagingUnitId === "remote")
          )
      )
    );
  }

  const relevantOutgoingsWithPUs: Array<Outgoing> = [];
  relevantOutgoings.forEach(o => {
    if (filter.length === 0) {
      relevantOutgoingsWithPUs.push(o);
      return;
    }
    const relPUs: Array<PackagingUnit> = [];
    o.movedPackagingUnits.forEach(p => {
      if (
        filter.some(
          f =>
            o.destination._id.toString() === f.destinationId &&
            o.batch._id.toString() === f.batchId &&
            (p._id.toString() === f.packagingUnitId || (p.quantity === null && f.packagingUnitId === "remote"))
        )
      ) {
        relPUs.push({ ...p });
      }
    });
    if (relPUs.length > 0) relevantOutgoingsWithPUs.push({ ...o, movedPackagingUnits: relPUs });
  });

  return [relevantOutgoingsWithPUs];
};

const getDefaultSettings = (
  relevantOutgoingsWithPUs: Array<Outgoing | OutgoingSnapshot>,
  delivery: Delivery | undefined
): DeliveryNoteSettings => {
  if (delivery) {
    return {
      recipientText: delivery.recipientText,
      title: delivery.title,
      eta: delivery.eta,
      additionalInformation: delivery.additionalInformation,
      positions: delivery.positions
    };
  }

  const settings = getEmptyDeliveryNoteSettings();

  if (relevantOutgoingsWithPUs.length === 0) {
    console.error("No outgoing documents found. Data cannot be loaded");
    return settings;
  }

  const destination = relevantOutgoingsWithPUs[0]?.destination;
  if (destination) {
    settings.recipientText = `${destination.address.name}
${destination.address.street} ${destination.address.houseNo}
${destination.address.postalCode} ${destination.address.city}
${getCountryNameForCode(destination.address.country) || destination.address.country}`;
  }

  const positions: Array<PositionEntry> = [];
  relevantOutgoingsWithPUs.forEach(o => {
    o.movedPackagingUnits.forEach(p => {
      positions.push({
        _id: new BSON.ObjectId(),
        packagingUnits:
          p.quantity === null
            ? resolveTranslation(p.puSnapshot.label).trim()
            : getFormattedPackagingUnitAmount(p, true).trim(),
        title: `${p.quantity !== null ? getFormattedPackagingUnitTotalAmount(p).trim() : ""} ${resolveTranslation(
          o.batch.content.details.title
        )}`.trim(),
        description: resolveTranslation(o.batch.content.details.subtitle).trim(),
        batch: _.pick(o.batch, ["_id", "lot"]) as DeliveryBatchSnapshot,
        universalBarcode: o.universalBarcode?.toString() || null
      });
    });
  });
  if (positions.length > 0) settings.positions = positions;

  const totalPUs = relevantOutgoingsWithPUs.reduce(
    (sum, o) => sum + o.movedPackagingUnits.reduce((sumP, p) => sumP + (p.quantity || 0), 0),
    0
  );
  settings.title = i18n.t("warehouse:deliveryPackagingUnits", { count: totalPUs });

  return settings;
};

const DeliveryNoteModal: React.FC<BaseActionModalProps> = ({ show, actionTrigger, onHide }) => {
  const warehouseContext = useWarehouseContext();
  const dataContext = useDataContext();
  const { selectedEntries, warehouseListingTabDocuments } = warehouseContext;
  const { universalBarcode, delivery: deliveries, updateDocumentInContext } = dataContext;

  const [saving, setSaving] = useState<boolean>(false);
  const [settings, setSettings] = useState<DeliveryNoteSettings>(getEmptyDeliveryNoteSettings());
  const { recipientText, title, eta, positions, additionalInformation } = settings;

  const [relevantOutgoingsWithPUs, delivery] = useMemo(
    () => getRelevantOutgoingsWithPUs(selectedEntries, actionTrigger, warehouseListingTabDocuments, deliveries),
    [selectedEntries, actionTrigger, warehouseListingTabDocuments, deliveries]
  );

  useEffect(() => {
    if (show) setSettings(getDefaultSettings(relevantOutgoingsWithPUs, delivery));
  }, [show]);

  const edit = useMemo(() => !!delivery, [delivery]);

  const errors = useMemo(() => {
    const errors: Array<string> = [];
    if (relevantOutgoingsWithPUs.length === 0) errors.push(i18n.t("warehouse:deliveryNoteDataLoadError"));
    if (!eta) errors.push(i18n.t("warehouse:deliveryNoteInvalidDateError"));
    if (!recipientText.trim()) errors.push(i18n.t("warehouse:deliveryNoteRecipientMissingError"));
    if (!title.trim()) errors.push(i18n.t("warehouse:deliveryNoteTitleMissingError"));
    positions.forEach((p, idx) => {
      const { title: pTitle, packagingUnits } = p;
      if (!pTitle.trim()) errors.push(i18n.t("warehouse:deliveryNotePositionTitleMissingError", { position: idx + 1 }));
      if (!packagingUnits.trim())
        errors.push(i18n.t("warehouse:deliveryNotePositionPUMissingError", { position: idx + 1 }));
    });
    return errors;
  }, [recipientText, title, positions, relevantOutgoingsWithPUs, eta]);

  const handleHide = () => {
    if (saving) return;
    onHide();
  };

  const handleSave = async () => {
    if (edit) await handleEdit();
    else await handleCreate();
  };

  const handleEdit = useCallback(async () => {
    if (!delivery) return;
    setSaving(true);
    const updatedFields = Object.entries(settings)
      .map(([key, value]) => {
        if (!_.isEqual(value, delivery[key as keyof DeliveryNoteSettings])) return key;
      })
      .filter(e => !!e) as Array<keyof DeliveryNoteSettings>;
    try {
      const [html, generationTime] = createDeliveryNote(settings, delivery.identifier, universalBarcode);
      const fileName = `${i18n.t("warehouse:deliveryNoteFileTitle")}-${
        delivery.identifier
      }-${new Date().toISOString()}.pdf`;
      const deliveryNotePDF = JSON.stringify({
        html,
        fileName
      });
      const path = await pdfUtils.uploadAndReturnPath(deliveryNotePDF);
      const fullPath = config.mediahubBase + path;
      window.open(fullPath, "_blank");

      const deliveryNoteDoc: DeliveryFile = {
        _id: new BSON.ObjectId(),
        path: fullPath,
        title: fileName,
        date: generationTime,
        person: userService.getUserSnapshot()
      };
      const update: object = {
        deliveryNote: deliveryNoteDoc
      };
      const pre: Partial<Delivery> = delivery.deliveryNote ? { deliveryNote: delivery.deliveryNote } : {};
      const post: Partial<Delivery> = { deliveryNote: deliveryNoteDoc };
      const timelineEntry = {
        _id: new BSON.ObjectId(),
        person: userService.getUserSnapshot(),
        date: new Date(),
        payload: {},
        diff: { pre, post }
      };

      updatedFields.forEach(key => {
        _.set(update, key, settings[key]);
        _.set(pre, key, delivery[key]);
        _.set(post, key, settings[key]);
      });

      const action: UpdateAction = {
        collection: DELIVERY,
        filter: { _id: delivery._id },
        update,
        push: { timeline: timelineEntry }
      };
      const fileResult = await dbService.transaction([action]);

      if (fileResult) toast.success(i18n.t("warehouse:deliveryNoteSuccess"));
      else toast.error(i18n.t("warehouse:deliveryNoteUploadError"));
      await updateDocumentInContext(DELIVERY, delivery._id.toString());
      onHide();
    } finally {
      setSaving(false);
    }
  }, [delivery, settings, relevantOutgoingsWithPUs, universalBarcode]);

  const handleCreate = useCallback(async () => {
    if (relevantOutgoingsWithPUs.length === 0) return;
    setSaving(true);
    try {
      const result = await dbService.callFunction<{ insertedId: BSON.ObjectId; identifier: string } | false>(
        "createDelivery",
        [settings, relevantOutgoingsWithPUs],
        true
      );
      if (result && result.identifier) {
        toast.success(i18n.t("warehouse:deliveryNoteSuccess"));
        const { identifier } = result;
        const [html, generationTime] = createDeliveryNote(settings, identifier, universalBarcode);
        const fileName = `${i18n.t("warehouse:deliveryNoteFileTitle")}-${identifier}-${new Date().toISOString()}.pdf`;
        const deliveryNotePDF = JSON.stringify({
          html,
          fileName
        });
        const path = await pdfUtils.uploadAndReturnPath(deliveryNotePDF);
        const fullPath = config.mediahubBase + path;
        window.open(fullPath, "_blank");
        const action: UpdateAction = {
          collection: DELIVERY,
          filter: { _id: result.insertedId },
          update: {
            deliveryNote: {
              _id: new BSON.ObjectId(),
              path: fullPath,
              title: fileName,
              date: generationTime,
              person: userService.getUserSnapshot()
            }
          }
        };
        const fileResult = await dbService.transaction([action]);
        if (!fileResult) toast.error(i18n.t("warehouse:deliveryNoteUploadError"));
        await updateDocumentInContext(DELIVERY, result.insertedId);
        for (let i = 0; i < relevantOutgoingsWithPUs.length; i++)
          await updateDocumentInContext(OUTGOING, relevantOutgoingsWithPUs[i]._id);
        onHide();
      } else {
        toast.error(i18n.t("warehouse:deliveryNoteCreationError"));
      }
    } finally {
      setSaving(false);
    }
  }, [settings, relevantOutgoingsWithPUs, universalBarcode]);

  const handleReset = () => {
    const [outgoings, delivery] = getRelevantOutgoingsWithPUs(
      selectedEntries,
      actionTrigger,
      warehouseListingTabDocuments,
      deliveries
    );
    setSettings(getDefaultSettings(outgoings, delivery));
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setSettings(prevSettings => ({ ...prevSettings, [name]: value }));
  };

  const handleChangeETA = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsDate;
    if (!value) return;
    setSettings(prevSettings => ({ ...prevSettings, eta: value }));
  };

  const handleChangePositionText = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: BSON.ObjectId | string
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    setSettings(prevSettings => {
      const newSettings = { ...prevSettings, positions: [...prevSettings.positions] };
      const position = newSettings.positions.find(p => p._id.toString() === id.toString());
      if (!position) return newSettings;
      _.set(position, name, value);
      return newSettings;
    });
  };

  const handleAddPosition = () => {
    setSettings(prevSettings => ({
      ...prevSettings,
      positions: [...prevSettings.positions, getEmptyDeliveryNotePosition()]
    }));
  };

  const handleRemovePosition = (id: BSON.ObjectId | string) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      positions: prevSettings.positions.filter(p => p._id.toString() !== id.toString())
    }));
  };

  return (
    <Modal show={show} onHide={handleHide} size={"lg"} centered name={"deliveryNoteSettings"}>
      <Modal.Header closeButton>
        <Modal.Title as={"h5"}>
          <b>{i18n.t("warehouse:deliveryNoteSettings")}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-2">
          <div className="row">
            <div className="col-6">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:deliveryNoteRecipient")}
              </span>
              <textarea
                className="form-control"
                rows={5}
                name={"recipientText"}
                value={recipientText}
                placeholder={i18n.t("warehouse:deliveryNoteRecipientPlaceholder")}
                onChange={handleChangeText}
              />
            </div>
            <div className="col-6">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:deliveryNoteETA")}
              </span>
              <DateInput name={"eta"} value={eta} onBlur={handleChangeETA} />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-3 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:deliveryNoteTitle")}
              </span>
            </div>
            <div className="col-12">
              <Input
                className="form-control"
                type="text"
                name={"title"}
                value={title}
                onChange={handleChangeText}
                placeholder={i18n.t("warehouse:deliveryNoteTitlePlaceholder")}
              />
            </div>
          </div>
          <div className="row mt-4 pb-2 bg-light">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-3" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:deliveryNotePositions")}
              </span>
            </div>
            {positions.map(p => (
              <DeliveryNoteModalPosition
                key={p._id.toString()}
                position={p}
                onChangePositionText={handleChangePositionText}
                onRemovePosition={handleRemovePosition}
              />
            ))}
            <div className="col-12 text-right">
              <div className="w-100">
                <small className="text-muted pointer" onClick={handleAddPosition}>
                  {i18n.t("warehouse:deliveryNoteAddPosition")}
                </small>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-4">
            <div className="col-12">
              <span className=" font-size-lg text-black d-block mt-4 mb-2" style={{ fontWeight: 500 }}>
                {i18n.t("warehouse:deliveryNoteAdditionalInformation")}
              </span>
              <textarea
                className="form-control"
                rows={5}
                placeholder={i18n.t("warehouse:deliveryNoteAdditionalInformationPlaceholder")}
                name={"additionalInformation"}
                value={additionalInformation}
                onChange={handleChangeText}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ErrorOverlayButton
          buttonText={i18n.t("common:close")}
          className={"btn btn-secondary"}
          saving={saving}
          onClick={handleHide}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("common:reset")}
          className={"btn btn-secondary"}
          saving={saving}
          onClick={handleReset}
        />
        <ErrorOverlayButton
          buttonText={i18n.t("warehouse:createDeliveryNote")}
          className={"btn btn-success"}
          errors={errors}
          saving={saving}
          onClick={handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};

interface DeliveryNoteModalPositionProps {
  position: PositionEntry;
  onChangePositionText: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: BSON.ObjectId | string
  ) => void;
  onRemovePosition: (id: BSON.ObjectId | string) => void;
}

const DeliveryNoteModalPosition: React.FC<DeliveryNoteModalPositionProps> = ({
  position,
  onChangePositionText,
  onRemovePosition
}) => {
  const { _id, title, packagingUnits, description, isFreePosition } = position;

  const handleChangePositionText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    onChangePositionText(e, _id);

  const handleRemovePosition = () => onRemovePosition(_id);

  return (
    <>
      <div className="col-3 mt-3">
        <Input
          className="form-control"
          type="text"
          name={"packagingUnits"}
          value={packagingUnits}
          onChange={handleChangePositionText}
          placeholder={i18n.t("warehouse:deliveryNotePositionPUPlaceholder")}
        />
        {isFreePosition && (
          <div className="w-100">
            <small className="mt-1 ml-1 text-muted pointer" onClick={handleRemovePosition}>
              {i18n.t("warehouse:deliveryNoteRemovePosition")}
            </small>
          </div>
        )}
      </div>
      <div className="col-9 text-right mt-3">
        <Input
          className="form-control"
          type="text"
          name={"title"}
          value={title}
          onChange={handleChangePositionText}
          disabled={!isFreePosition}
          placeholder={i18n.t("warehouse:deliveryNotePositionTitlePlaceholder")}
        />
        <textarea
          rows={2}
          className="form-control mt-2"
          name={"description"}
          value={description}
          onChange={handleChangePositionText}
          placeholder={i18n.t("warehouse:deliveryNotePositionDescriptionPlaceholder")}
        />
      </div>
    </>
  );
};

export default DeliveryNoteModal;
